import BoardStateContainer from 'components/board-state-container/BoardStateContainer';
import 'components/board-container/BoardContainer.css';
import { Client } from 'boardgame.io/react';
import { Local } from 'boardgame.io/multiplayer';
import BoardLayout from 'components/board-layout/BoardLayout';
import { R2R } from 'components/game/Game';
import BotControls from 'components/bot/BotControls';
import { DEBUG_MODE } from 'constants/GameData';

const StateContainer = Client({ game: R2R, board: BoardStateContainer, numPlayers: 2, debug: DEBUG_MODE, multiplayer: Local() });

export default function BoardContainer() {
    return (
        <div id="board-container" className="relative m-auto duration-1000 blur-full-on">
            <BoardLayout />
            <StateContainer playerID="0" matchID="single-vs-ai" />
            <BotControls playerID="1" matchID="single-vs-ai" />
        </div>
    );
}
