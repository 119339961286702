import { Questionnaire, QuestionnaireObjectType } from './Questionnaires';

export enum CardType {
    SayWatt,
    Bolt,
}

export enum CategoryType {
    Solar,
    Wind,
    SolarAndWind,
    EnergyEfficiency,
}

export enum ActionType {
    UnitMovement,
    SquareMovement,
}

export class Card {
    CardId: string;
    Type: CardType;
    Category: CategoryType;
    Action: ActionType;
    Description: string;
    SubText: string;
    MoveValue: number | null;
    ImageUrl: string;
    Questionnaire: Questionnaire | null;
    constructor(data: any) {
        this.Category = data.Category;
        this.CardId = data.CardId;
        this.ImageUrl = data.ImageUrl;
        this.Description = data.Description;
        this.Type = data.Type;
        this.Action = data.Type === CardType.SayWatt ? ActionType.UnitMovement : data.Action;
        this.MoveValue = data.Action === ActionType.UnitMovement ? data.MoveValue : null;
        this.SubText = data.SubText ?? '';
        this.Questionnaire = data.Type === CardType.SayWatt ? data.Questionnaire : null;
    }
}

export type CardObjectType = {
    CardId: string;
    Type: CardType;
    Category: CategoryType;
    Action: ActionType;
    Description: string;
    MoveValue: number | null;
    ImageUrl: string;
    Questionnaire: QuestionnaireObjectType | null;
};
