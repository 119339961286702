import { useEffect, useState } from 'react';
import { MCTSBot, Step } from 'boardgame.io/ai';
import { Local } from 'boardgame.io/multiplayer';
import { Client as PlainJSClient } from 'boardgame.io/client';
import { R2R } from 'components/game/Game';

const BotControls = ({ playerID, matchID }: any) => {
    const difficulties: any = {
        easy: {
            iterations: 1,
            playoutDepth: 1,
        },
        hard: {
            iterations: 1000,
            playoutDepth: 50,
        },
    };
    const [difficulty] = useState<string>('easy');
    const [client, setClient] = useState<any>();

    // Create a plain Javascript boardgame.io client on mount.
    useEffect(() => {
        const newClient = PlainJSClient({
            game: R2R,
            debug: false,
            multiplayer: Local(),
            matchID,
            playerID,
        });
        newClient.start();
        setClient(newClient);
        // Clean up client on unmount.
        return () => newClient.stop();
    }, []);

    // Update the client subscription when bot difficulty changes.
    useEffect(() => {
        if (!client) return;
        // Subscribe to the client with a function that will run AI on a bot
        // player’s turn.
        return client.subscribe((state: any) => {
            if (!state) return;
            if (state.ctx.currentPlayer === playerID) {
                const { iterations, playoutDepth } = difficulties[difficulty];
                const bot = new MCTSBot({
                    game: R2R,
                    enumerate: R2R!.ai!.enumerate,
                    iterations,
                    playoutDepth,
                });
                // Delay AI stepping by a tick to allow React to render before the
                // main thread gets blocked by AI iterations.
                setTimeout(() => Step(client, bot), 1000);
            }
        });
    }, [client, difficulty]);

    // Render AI difficulty toggle buttons.
    return (
        //   <p>
        //     AI Difficulty:{' '}
        //     <button
        //       onClick={() => setDifficulty('easy')}
        //       disabled={difficulty === 'easy'}
        //     >
        //       Easy
        //     </button>
        //     <button
        //       onClick={() => setDifficulty('hard')}
        //       disabled={difficulty === 'hard'}
        //     >
        //       Hard
        //     </button>
        //   </p>
        <></>
    );
};

export default BotControls;
