import { Ctx, Game } from 'boardgame.io';
import { MCTSBot } from 'boardgame.io/ai';
import { TurnOrder } from 'boardgame.io/core';
import { BOT_EASY_MODE_CORRECT_QUIZ_PROBABILITY_PERCENTAGE, BOT_EASY_MODE_QUIZ_CARD_PROBABILITY_PERCENTAGE, BOT_HARD_MODE_CORRECT_QUIZ_PROBABILITY_PERCENTAGE } from 'constants/BotData';
import { ENDING_TILE, FIRST_AC_TILE, FIRST_DC_TILE, FIRST_SOLAR_PANEL, FIRST_WIND_TURBINE, PEAK_LOAD_PANIC_TILE, RENEWABLE_ENERGY_TILE, SECOND_AC_TILE, SECOND_DC_TILE, SECOND_SOLAR_PANEL, SECOND_WIND_TURBINE, STARTING_TILE } from 'constants/GameData';
import { cardData } from 'data/card.data';
import { GameHelper } from 'helpers/GameHelper';
import { CardType, ActionType, CategoryType, Card, CardObjectType } from 'models/Card';
import { DeckObjectType } from 'models/Deck';
import { Token } from 'models/Game.model';
import { OptionsObjectType, QuestionnaireOption } from 'models/Questionnaires';

export type GameState = {
    deck: DeckObjectType;
    bufferDeck: DeckObjectType;
    computerTokenPosition: number;
    computerMiddleTokenPosition: number;
    computerToken?: Token;
    humanTokenPosition: number;
    humanMiddleTokenPosition: number;
    humanToken?: Token;
    computerLastPlayedCard?: CardObjectType;
    computerLastAnswerCorrect?: boolean;
    humanLastPlayedCard?: CardObjectType;
    isHumanSetPosition?: boolean;
    isComputerSetPosition?: boolean;
    shouldHumanShake?: boolean;
    shouldComputerShake?: boolean;
};

const drawCard = (G: GameState, ctx: Ctx) => {
    G.deck.Cards = G.deck.Cards.filter((x, i) => i !== 0);
};

const playCard = (G: GameState, ctx: Ctx, answer?: number) => {
    const playedCard: CardObjectType = G.deck.Cards[0];
    const playerInfo = GameHelper.getPlayerInfo();
    ctx.currentPlayer === '0' ? (G.shouldHumanShake = false) : (G.shouldComputerShake = false);

    if (playedCard.Type === CardType.SayWatt) {
        if (ctx.currentPlayer === '0') {
            const correctAnswer = playedCard.Questionnaire?.Options.findIndex((opt: OptionsObjectType) => !!opt.IsAnswer)!;
            let tempMoveValue = 0;
            if (correctAnswer === answer) {
                // human answered correctly
                tempMoveValue += playedCard.MoveValue!;
            } else {
                // human answered incorrectly
                // tempMoveValue -= playedCard.MoveValue!;
            }
            let humanPositionAfterFirstMove = G.humanTokenPosition + tempMoveValue;

            // comment this piece of code for previous functionality
            if (G.humanTokenPosition >= RENEWABLE_ENERGY_TILE && humanPositionAfterFirstMove < RENEWABLE_ENERGY_TILE) {
                humanPositionAfterFirstMove = RENEWABLE_ENERGY_TILE;
            }

            // if (G.humanTokenPosition === 0 && playedCard.MoveValue! < 0) G.humanTokenPosition += 0;
            if (humanPositionAfterFirstMove < STARTING_TILE) {
                G.humanMiddleTokenPosition = G.humanTokenPosition = 0;
            } else if (humanPositionAfterFirstMove >= ENDING_TILE) {
                G.humanTokenPosition = G.humanMiddleTokenPosition = ENDING_TILE;
            } else if (humanPositionAfterFirstMove === FIRST_SOLAR_PANEL) {
                G.humanMiddleTokenPosition = FIRST_SOLAR_PANEL;
                G.humanTokenPosition = SECOND_SOLAR_PANEL;
            } else if (humanPositionAfterFirstMove === FIRST_WIND_TURBINE) {
                G.humanMiddleTokenPosition = FIRST_WIND_TURBINE;
                G.humanTokenPosition = SECOND_WIND_TURBINE;
            } else if (humanPositionAfterFirstMove === FIRST_DC_TILE) {
                G.humanMiddleTokenPosition = FIRST_DC_TILE;
                G.humanTokenPosition = FIRST_AC_TILE;
            } else if (humanPositionAfterFirstMove === SECOND_DC_TILE) {
                G.humanMiddleTokenPosition = SECOND_DC_TILE;
                G.humanTokenPosition = SECOND_AC_TILE;
            } else if (humanPositionAfterFirstMove === PEAK_LOAD_PANIC_TILE) {
                G.humanTokenPosition = PEAK_LOAD_PANIC_TILE - 2;
                G.humanMiddleTokenPosition = PEAK_LOAD_PANIC_TILE;
            } else G.humanTokenPosition = G.humanMiddleTokenPosition = humanPositionAfterFirstMove;

            // if (G.humanTokenPosition === G.computerTokenPosition) {
            //     // G.isComputerSetPosition = true;
            //     if (G.computerTokenPosition < RENEWABLE_ENERGY_TILE) {
            //         G.computerTokenPosition = G.computerMiddleTokenPosition = STARTING_TILE;
            //     } else {
            //         G.computerTokenPosition = G.computerMiddleTokenPosition = RENEWABLE_ENERGY_TILE;
            //     }
            // }
        } else {
            const rand = Math.floor(Math.random() * 10);
            let tempMoveValue = 0;
            if(playedCard.Action === ActionType.UnitMovement && playedCard.MoveValue === 1) {
                tempMoveValue += playedCard.MoveValue!;
                G.computerLastAnswerCorrect = true;
            } else if (rand >= (playerInfo?.eighteenplus.toLowerCase() === 'yes' ? BOT_HARD_MODE_CORRECT_QUIZ_PROBABILITY_PERCENTAGE : BOT_EASY_MODE_CORRECT_QUIZ_PROBABILITY_PERCENTAGE) / 10) {
                // bot answered incorrectly
                // tempMoveValue -= playedCard.MoveValue!;
                G.computerLastAnswerCorrect = false;
            } else {
                // bot answered correctly
                tempMoveValue += playedCard.MoveValue!;
                G.computerLastAnswerCorrect = true;
            }

            let computerPositionAfterFirstMove = G.computerTokenPosition + tempMoveValue;

            // comment this piece of code for previous functionality
            if (G.computerTokenPosition >= RENEWABLE_ENERGY_TILE && computerPositionAfterFirstMove < RENEWABLE_ENERGY_TILE) {
                computerPositionAfterFirstMove = RENEWABLE_ENERGY_TILE;
            }

            // if (G.computerTokenPosition === 0 && playedCard.MoveValue! < 0) G.computerTokenPosition += 0;
            if (computerPositionAfterFirstMove < STARTING_TILE) {
                G.computerMiddleTokenPosition = G.computerTokenPosition = 0;
            } else if (computerPositionAfterFirstMove >= ENDING_TILE) {
                G.computerTokenPosition = G.computerMiddleTokenPosition = ENDING_TILE;
            } else if (computerPositionAfterFirstMove === FIRST_SOLAR_PANEL) {
                G.computerMiddleTokenPosition = FIRST_SOLAR_PANEL;
                G.computerTokenPosition = SECOND_SOLAR_PANEL;
            } else if (computerPositionAfterFirstMove === FIRST_WIND_TURBINE) {
                G.computerMiddleTokenPosition = FIRST_WIND_TURBINE;
                G.computerTokenPosition = SECOND_WIND_TURBINE;
            } else if (computerPositionAfterFirstMove === FIRST_DC_TILE) {
                G.computerMiddleTokenPosition = FIRST_DC_TILE;
                G.computerTokenPosition = FIRST_AC_TILE;
            } else if (computerPositionAfterFirstMove === SECOND_DC_TILE) {
                G.computerMiddleTokenPosition = SECOND_DC_TILE;
                G.computerTokenPosition = SECOND_AC_TILE;
            } else if (computerPositionAfterFirstMove === PEAK_LOAD_PANIC_TILE) {
                G.computerTokenPosition = PEAK_LOAD_PANIC_TILE - 2;
                G.computerMiddleTokenPosition = PEAK_LOAD_PANIC_TILE;
            } else G.computerTokenPosition = G.computerMiddleTokenPosition = computerPositionAfterFirstMove;

            // if (G.computerTokenPosition === G.humanTokenPosition) {
            //     // G.isHumanSetPosition = true;
            //     if (G.humanTokenPosition < RENEWABLE_ENERGY_TILE) {
            //         G.humanTokenPosition = G.humanMiddleTokenPosition = STARTING_TILE;
            //     } else {
            //         G.humanTokenPosition = G.humanMiddleTokenPosition = RENEWABLE_ENERGY_TILE;
            //     }
            // }
        }
    } else {
        if (ctx.currentPlayer === '0') {
            if (playedCard.Action === ActionType.UnitMovement) {
                let humanPositionAfterFirstMove = G.humanTokenPosition + playedCard.MoveValue!;

                // comment this piece of code for previous functionality
                if (G.humanTokenPosition >= RENEWABLE_ENERGY_TILE && humanPositionAfterFirstMove < RENEWABLE_ENERGY_TILE) {
                    G.shouldHumanShake = true;
                    humanPositionAfterFirstMove = RENEWABLE_ENERGY_TILE;
                }

                // if (G.humanTokenPosition === 0 && playedCard.MoveValue! < 0) G.humanTokenPosition += 0;
                if (humanPositionAfterFirstMove < STARTING_TILE) {
                    G.shouldHumanShake = true;
                    G.humanMiddleTokenPosition = G.humanTokenPosition = 0;
                } else if (humanPositionAfterFirstMove >= ENDING_TILE) {
                    G.humanTokenPosition = G.humanMiddleTokenPosition = ENDING_TILE;
                } else if (humanPositionAfterFirstMove === FIRST_SOLAR_PANEL) {
                    G.humanMiddleTokenPosition = FIRST_SOLAR_PANEL;
                    G.humanTokenPosition = SECOND_SOLAR_PANEL;
                } else if (humanPositionAfterFirstMove === FIRST_WIND_TURBINE) {
                    G.humanMiddleTokenPosition = FIRST_WIND_TURBINE;
                    G.humanTokenPosition = SECOND_WIND_TURBINE;
                } else if (humanPositionAfterFirstMove === FIRST_DC_TILE) {
                    G.humanMiddleTokenPosition = FIRST_DC_TILE;
                    G.humanTokenPosition = FIRST_AC_TILE;
                } else if (humanPositionAfterFirstMove === SECOND_DC_TILE) {
                    G.humanMiddleTokenPosition = SECOND_DC_TILE;
                    G.humanTokenPosition = SECOND_AC_TILE;
                } else if (humanPositionAfterFirstMove === PEAK_LOAD_PANIC_TILE) {
                    G.humanTokenPosition = PEAK_LOAD_PANIC_TILE - 2;
                    G.humanMiddleTokenPosition = PEAK_LOAD_PANIC_TILE;
                } else G.humanTokenPosition = G.humanMiddleTokenPosition = humanPositionAfterFirstMove;
            } else {
                // G.humanTokenPosition += 24;
                if (playedCard.Category === CategoryType.Solar) {
                    const { middle, final } = GameHelper.findNextSolarPanels(G.humanTokenPosition);
                    G.humanMiddleTokenPosition = middle;
                    G.humanTokenPosition = middle === PEAK_LOAD_PANIC_TILE ? SECOND_AC_TILE : final;
                } else if (playedCard.Category === CategoryType.Wind) {
                    const { middle, final } = GameHelper.findNextWindTurbines(G.humanTokenPosition);
                    G.humanMiddleTokenPosition = middle;
                    G.humanTokenPosition = final;
                }
            }
            // if (G.humanTokenPosition === G.computerTokenPosition) {
            //     // G.isComputerSetPosition = true;
            //     G.shouldComputerShake = true;
            //     if (G.computerTokenPosition < RENEWABLE_ENERGY_TILE) {
            //         G.computerTokenPosition = G.computerMiddleTokenPosition = STARTING_TILE;
            //     } else {
            //         G.computerTokenPosition = G.computerMiddleTokenPosition = RENEWABLE_ENERGY_TILE;
            //     }
            // }
        } else {
            if (playedCard.Action === ActionType.UnitMovement) {
                let computerPositionAfterFirstMove = G.computerTokenPosition + playedCard.MoveValue!;

                // comment this piece of code for previous functionality
                if (G.computerTokenPosition >= RENEWABLE_ENERGY_TILE && computerPositionAfterFirstMove < RENEWABLE_ENERGY_TILE) {
                    G.shouldComputerShake = true;
                    computerPositionAfterFirstMove = RENEWABLE_ENERGY_TILE;
                }

                // if (G.computerTokenPosition === 0 && playedCard.MoveValue! < 0) G.computerTokenPosition += 0;
                if (computerPositionAfterFirstMove < STARTING_TILE) {
                    G.shouldComputerShake = true;
                    G.computerMiddleTokenPosition = G.computerTokenPosition = 0;
                } else if (computerPositionAfterFirstMove >= ENDING_TILE) {
                    G.computerTokenPosition = G.computerMiddleTokenPosition = ENDING_TILE;
                } else if (computerPositionAfterFirstMove === FIRST_SOLAR_PANEL) {
                    G.computerMiddleTokenPosition = FIRST_SOLAR_PANEL;
                    G.computerTokenPosition = SECOND_SOLAR_PANEL;
                } else if (computerPositionAfterFirstMove === FIRST_WIND_TURBINE) {
                    G.computerMiddleTokenPosition = FIRST_WIND_TURBINE;
                    G.computerTokenPosition = SECOND_WIND_TURBINE;
                } else if (computerPositionAfterFirstMove === FIRST_DC_TILE) {
                    G.computerMiddleTokenPosition = FIRST_DC_TILE;
                    G.computerTokenPosition = FIRST_AC_TILE;
                } else if (computerPositionAfterFirstMove === SECOND_DC_TILE) {
                    G.computerMiddleTokenPosition = SECOND_DC_TILE;
                    G.computerTokenPosition = SECOND_AC_TILE;
                } else if (computerPositionAfterFirstMove === PEAK_LOAD_PANIC_TILE) {
                    G.computerTokenPosition = PEAK_LOAD_PANIC_TILE - 2;
                    G.computerMiddleTokenPosition = PEAK_LOAD_PANIC_TILE;
                } else G.computerTokenPosition = G.computerMiddleTokenPosition = computerPositionAfterFirstMove;
            } else {
                // G.computerTokenPosition += 24;
                if (playedCard.Category === CategoryType.Solar) {
                    const { middle, final } = GameHelper.findNextSolarPanels(G.computerTokenPosition);
                    G.computerMiddleTokenPosition = middle;
                    G.computerTokenPosition = middle === PEAK_LOAD_PANIC_TILE ? SECOND_AC_TILE : final;
                } else if (playedCard.Category === CategoryType.Wind) {
                    const { middle, final } = GameHelper.findNextWindTurbines(G.computerTokenPosition);
                    G.computerMiddleTokenPosition = middle;
                    G.computerTokenPosition = final;
                }
            }
            // if (G.computerTokenPosition === G.humanTokenPosition) {
            //     // G.isHumanSetPosition = true;
            //     if (G.humanTokenPosition < RENEWABLE_ENERGY_TILE) {
            //         G.humanTokenPosition = G.humanMiddleTokenPosition = STARTING_TILE;
            //     } else {
            //         G.humanTokenPosition = G.humanMiddleTokenPosition = RENEWABLE_ENERGY_TILE;
            //     }
            // }
        }
    }
    ctx.currentPlayer === '0' ? (G.humanLastPlayedCard = playedCard) : (G.computerLastPlayedCard = playedCard);
    // if (ctx.currentPlayer === '0') G.humanTokenPosition = G.humanMiddleTokenPosition = 35;
    // if (ctx.currentPlayer === '1') G.computerTokenPosition = G.computerMiddleTokenPosition = 35;
    // if (ctx.currentPlayer === '0') {
    //     G.isHumanSetPosition = false;
    //     G.humanLastPlayedCard = playedCard;
    // } else {
    //     G.isComputerSetPosition = false;
    //     G.computerLastPlayedCard = playedCard;
    // }
    G.bufferDeck.Cards.push(playedCard);
    drawCard(G, ctx);
};

export const R2R: Game<GameState> = {
    name: 'RoadToRenewable',
    setup: (ctx: Ctx): GameState => {
        const gameData = GameHelper.getGameData();
        if (gameData) return gameData;
        else {
            let cards: CardObjectType[] = cardData.map((d: Card): CardObjectType => {
                if (d.Type !== CardType.SayWatt) return { ...d };
                else return { ...d, Questionnaire: { ...d.Questionnaire!, Options: [...d.Questionnaire?.Options!.map((opt: QuestionnaireOption): OptionsObjectType => ({ ...opt }))!] } };
            });

            // shuffle deck 3 times
            cards = GameHelper.Shuffle(3, cards);

            const deckObj: DeckObjectType = { Cards: cards, DeckId: '0' } as DeckObjectType;

            // initialize buffer deck
            const bufferDeckObj: DeckObjectType = { Cards: [], DeckId: '1' };
            return {
                bufferDeck: bufferDeckObj,
                deck: deckObj,
                computerTokenPosition: 0,
                computerMiddleTokenPosition: 0,
                humanTokenPosition: 0,
                humanMiddleTokenPosition: 0,
            };
        }
        // initialize game
        // initialize main deck
    },
    moves: { playCard },
    turn: {
        maxMoves: 1,
        minMoves: 1,
        // game starts with human first
        order: TurnOrder.CUSTOM(['0', '1']),
    },
    endIf: (G: GameState, ctx: Ctx) => {
        if (G.computerTokenPosition === ENDING_TILE || G.humanTokenPosition === ENDING_TILE) {
            console.log(`Winner is ${ctx.currentPlayer === '0' ? 'Human' : 'Computer'}`);
            return { winner: ctx.currentPlayer };
        }
    },
    phases: {
        gameStart: {
            start: true,
            onBegin: (G: GameState, ctx: Ctx) => {
                const gameData = GameHelper.getGameData();
            },
            endIf: (G: GameState, ctx: Ctx) => G.deck.Cards.length === 0,
            next: 'swapDeck',
        },
        swapDeck: {
            onBegin: (G: GameState, ctx: Ctx) => {
                G.deck.Cards = GameHelper.Shuffle(3, G.bufferDeck.Cards);
                G.bufferDeck.Cards = [];
            },
            endIf: (G: GameState, ctx: Ctx) => G.deck.Cards.length > 0,
            next: 'gameStart',
        },
    },
    ai: {
        enumerate: (G: GameState, ctx: Ctx) => {
            return [{ move: 'playCard', args: [0] }];
        },
    },
};

export const bot = new MCTSBot({ game: R2R, enumerate: R2R.ai, playoutDepth: 1, iterations: 1 });
