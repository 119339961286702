export const GAME_COUNTDOWN_TIMER_INTERVAL = 5000;
export const ANSWER_REVEAL_DELAY = 1500;
export const HUMAN_PLAYER_DELAY = 3000;
export const SHUFFLE_TIME = 3000
export const DELAY_ON_COMPUTER_CARD_DRAW = false;
export const PLAYER_DEFAULT_AGE = 15;
export const DEBUG_MODE = false;
export const FOOTER_TYPE_SPEED = 10 //ms
export const TOKEN_SHAKE_DURATION = 3000

export const STARTING_TILE = 0;
export const ENDING_TILE = 35;
export const FIRST_SOLAR_PANEL = 7;
export const SECOND_SOLAR_PANEL = 13;
export const FIRST_WIND_TURBINE = 21;
export const SECOND_WIND_TURBINE = 27;
export const FIRST_DC_TILE = 10;
export const SECOND_DC_TILE = 24;
export const FIRST_AC_TILE = 15;
export const SECOND_AC_TILE = 29;
export const RENEWABLE_ENERGY_TILE = 19;
export const PEAK_LOAD_PANIC_TILE = 31;
