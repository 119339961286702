import './assets/styles/main.css';
import './assets/styles/custom.css';
import BoardContainer from 'components/board-container/BoardContainer';
import { useEffect } from 'react';

export default function App() {
    useEffect(() => {
        if (window.innerHeight < 883.48) {
            const fullContainer = document.getElementById('full-game-container');
            fullContainer!.style.transform = 'scale(' + window.innerHeight / 883.48 + ')';
            fullContainer!.classList.remove('m-auto');
            fullContainer!.classList.add('mx-auto');
            // document.getElementById('root')!.style.height = 'auto';
            document.getElementById('root')!.style.overflowY = 'hidden';
        }
    }, []);

    return (
        // <div className="bg-gray-900 p-20 h-screen flex justify-center items-start flex-col">
        //     <h1 className="text-5xl text-white">Hello, Welcome to Road to Renewable 👋</h1>
        //     <p className="text-gray-400 mt-5 text-lg">Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit consequuntur odio aut nobis ab quis? Reiciendis doloremque ut quo fugiat eveniet tempora, atque alias earum ullam inventore itaque sapiente iste?</p>
        //     <button className="p-4 bg-green-600 rounded-lg font-bold text-white mt-5 hover:bg-gray-600">Hello Friends 🚀</button>
        // </div>
        <div className="h-full flex">
            <div className="m-auto" id="full-game-container" style={{ transformOrigin: 'top' }}>
                <div
                    className="text-center game-title w-full py-2"
                    style={{
                        color: 'white',
                        backgroundColor: 'rgba(58,58,58,0.7)',
                        textShadow: 'black 0px 10px 5px',
                        fontSize: '25px',
                        // fontFamily: 'Calps Slim',
                        fontStyle: 'italic',
                        fontFamily: 'Greycliff CF'

                    }}
                >
                    Road to Renewable
                </div>
                <div className="flex overflow-hidden">
                    <BoardContainer />
                </div>
            </div>
        </div>
    );
}
