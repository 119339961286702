import { CategoryType } from "models/Card";


export class QuestionnaireOption {
    OptionId: string;
    Title: string;
    IsAnswer: boolean;
    constructor(data: any) {
        this.OptionId = data.OptionId;
        this.Title = data.Title;
        this.IsAnswer = data.IsAnswer;
    }
}

export class Questionnaire {
    QuestionnaireId: string;
    Question: string;
    Category: CategoryType;
    Options: QuestionnaireOption[];
    constructor(data: any) {
        this.Category = data.Category;
        this.Question = data.Question;
        this.Options = data.Options;
        this.QuestionnaireId = data.QuestionnaireId;
    }
}

export type OptionsObjectType = {
    OptionId: string;
    Title: string;
    IsAnswer: boolean;
};

export type QuestionnaireObjectType = {
    QuestionnaireId: string;
    Question: string;
    Category: CategoryType;
    Options: OptionsObjectType[];
};
