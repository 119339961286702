import Token from 'components/token/Token';
import 'components/welcome-message/WelcomeMessage.css';
import { AudioHelper } from 'helpers/AudioHelper';
import { GameHelper } from 'helpers/GameHelper';
import { PlatformDetector } from 'helpers/platform-lite';
import { useEffect } from 'react';

export default function WelcomeMessage({ onSubmitCompletion, showPopup, G, setComputerToken, setHumanToken }: any) {
    useEffect(() => {
        const playerInfo = GameHelper.getPlayerInfo();
        const gameData = GameHelper.getGameData();
        const params: any = GameHelper.getQueryParamsObject({ replay: false });

        if (params.age || (params.eighteenplus && params.eighteenplus.toLowerCase() !== 'null')) {
            GameHelper.setPlayerInfo({ ...params, default: false });
        } else {
            GameHelper.setPlayerInfo({ eighteenplus: 'no', replay: true, default: true });
        }

        console.log(params, playerInfo, GameHelper.checkObjectEquals({ ...params, replay: false }, { ...playerInfo, replay: false }), gameData);

        if (showPopup) {
            if (playerInfo && GameHelper.checkObjectEquals({ ...params, replay: false, default: false }, { ...playerInfo, replay: false, default: false }) && !playerInfo.replay && gameData) {
                GameHelper.setGameData(G);
                GameHelper.updatePlayerInfo({ replay: false });
            } else {
                GameHelper.clearGameData();
            }
            setTimeout(() => {
                setTimeout(() => {
                    const browserInfo: any = PlatformDetector.detect();
                    if (browserInfo.browser !== 'Safari' && !['Mac OS X', 'Mac OS'].includes(browserInfo.os)) {
                        AudioHelper.playSound(AudioHelper.Welcome_Message_Appear);
                    }
                }, 700);
                document.getElementById('log-in-pop-up')?.classList.add('squeeze-in');
            }, 1000);
        }
    }, [showPopup]);

    const continueGame = () => {
        setTimeout(() => {
            AudioHelper.playSound(AudioHelper.Welcome_Message_Gone);
        }, 500);
        document.getElementById('log-in-pop-up')?.classList.add('squeeze-out');
        document.getElementById('board-container')?.classList.remove('blur-full-on');
        setTimeout(() => {
            onSubmitCompletion();
        }, 1000);
    };

    const handleTokenSelect = (token: string) => {
        const computerToken = ['Exceptional-Energy', 'Phenomenal-Photon', 'Prolific-Power-Grid', 'Selfless-Solar', 'Whimsical-Wind'].filter((compTokens) => compTokens !== token)[GameHelper.randomGenerator(0, 3)];
        setComputerToken(computerToken);
        setHumanToken(token);
        continueGame();
    };

    const restartGame = () => {
        GameHelper.clearGameData();
        // GameHelper.clearPlayerInfo();
        GameHelper.updatePlayerInfo({ replay: true });
        window.location.reload();
    };

    return (
        <>
            <div id="log-in-pop-up" className="log-in-pop-up bg-white rounded-lg py-6 px-12 mx-12 w-1/2 duration-500 ignore-grayscale" style={{ fontFamily: 'Greycliff CF', fontStyle: 'italic' }}>
                <div className="bg-pattern duration-1000" style={{ backgroundImage: `url('/images/Pattern.png')`, backgroundSize: '1150px', backgroundPosition: 'center' }}></div>
                {GameHelper.getGameData() && (
                    <>
                        <div className="text-2xl text-center">Previous game data found.</div>
                        <div className="mt-5 flex justify-around items-center">
                            <div className="hover-color cursor-pointer duration-100" onClick={continueGame}>
                                Continue
                            </div>
                            <div className="hover-color cursor-pointer duration-100" onClick={restartGame}>
                                Restart
                            </div>
                        </div>
                    </>
                )}

                {!GameHelper.getGameData() && (
                    <>
                        <div className="text-4xl text-center">Welcome {GameHelper.getPlayerInfo() && GameHelper.getPlayerInfo().fname && GameHelper.getPlayerInfo().fname.toLowerCase() !== 'null' ? GameHelper.getPlayerInfo().fname : ''}</div>
                        <div className="text-2xl text-center mt-2">Let's get started! Choose your clean energy token.</div>
                        <div className="mt-20 mb-4 flex justify-around items-center">
                            <div className="flex justify-around" style={{ width: 'calc(100% - 20px)' }}>
                                <div className="token-choose">
                                    <img onClick={() => handleTokenSelect('Exceptional-Energy')} className="cursor-pointer w-16" src="/images/tokens/Exceptional-Energy.png" alt="token" />
                                    <div>Exceptional Energy</div>
                                </div>
                                <div className="token-choose">
                                    <img onClick={() => handleTokenSelect('Phenomenal-Photon')} className="cursor-pointer w-16" src="/images/tokens/Phenomenal-Photon.png" alt="token" />
                                    <div>Phenomenal Photon</div>
                                </div>
                                <div className="token-choose">
                                    <img onClick={() => handleTokenSelect('Prolific-Power-Grid')} className="cursor-pointer w-16" src="/images/tokens/Prolific-Power-Grid.png" alt="token" />
                                    <div>Prolific Power Grid</div>
                                </div>
                                <div className="token-choose">
                                    <img onClick={() => handleTokenSelect('Selfless-Solar')} className="cursor-pointer w-16" src="/images/tokens/Selfless-Solar.png" alt="token" />
                                    <div>Selfless Solar</div>
                                </div>
                                <div className="token-choose">
                                    <img onClick={() => handleTokenSelect('Whimsical-Wind')} className="cursor-pointer w-16" src="/images/tokens/Whimsical-Wind.png" alt="token" />
                                    <div>Whimsical Wind</div>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </>
    );
}
