import 'components/bouncing-arrow/BouncingArrow.css';

interface IBouncingArrow {
    className?: string;
}

export default function BouncingArrow({ className }: IBouncingArrow) {
    return (
        <img id="arrow" className={`arrow duration-1000 ` + (className ? className : '')} src="/images/arrow.png" alt="" />
    );
}
