import 'components/wind/Wind.css';

export default function Wind(props: any) {
    return (
        <div id={props.id} style={props.style}>
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 800 400">
                <defs>
                    <symbol id="wind-one" viewBox="-10 -10 220 100" preserveAspectRatio="none">
                        <path
                            fill="none"
                            stroke="#FFFFFF"
                            strokeMiterlimit="10"
                            d="M198.36,63.26c-16.89-13.33-36.29-25.2-85.14-3.35
        S5.9,85.45,0.39,38.61S50.27-7.5,60.57,14.63s-1.76,40.88-18.98,34.01S24.87,17.44,42.12,27.6"
                        />
                    </symbol>
                    <symbol id="wind-two" viewBox="-10 -10 220 100" preserveAspectRatio="none">
                        <path
                            fill="none"
                            stroke="#FFFFFF"
                            strokeMiterlimit="10"
                            d="M126.18,5.53C67.68-4.05,10.55-3.91,1.37,28.27
        s30.53,47.04,41.45,42.04s21.94-15.4,16.15-28.88s-31.06-7-31.51,0S23.82,54.15,35,54.55"
                        />
                    </symbol>
                    <symbol id="wind-three" viewBox="-10 -10 220 100" preserveAspectRatio="none">
                        <path
                            fill="none"
                            stroke="#FFFFFF"
                            strokeMiterlimit="10"
                            d="M166.93,65.99c-16.84-8.37-57.49,2.93-73.86,6.55
        S11.32,99.15,0.95,41.63C-2.49,22.5,3.78,10.82,13.74,4.87c20-11.92,54.9-0.71,56.08,19.88c1.77,30.86-29.6,38.59-42.23,13.86
        c-8.94-17.5,21.22-15.44,15.86-4.22"
                        />
                    </symbol>
                </defs>

                <g transform="translate(450, 60)">
                    <use className="bottom" x="-450" y="-60" id="bottom" xlinkHref="#hair-bottom" stroke="#1d1d1d" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" width="500" height="400" />
                </g>
                <g transform="translate(450, 60)">
                    <use className="middle" x="-450" y="-60" xlinkHref="#hair-middle" stroke="#1d1d1d" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" width="500" height="400" />
                </g>
                <use xlinkHref="#body" stroke="#1d1d1d" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" width="500" height="400" />
                <g transform="translate(450, 60)">
                    <use className="top" x="-450" y="-60" xlinkHref="#hair-top" stroke="#1d1d1d" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" width="500" height="400" />
                </g>

                <use className="wind" xlinkHref="#wind-one" x="50" y="100" width="600" height="200" strokeWidth="3" strokeLinecap="round" />
                <use className="wind" xlinkHref="#wind-three" x="150" y="0" width="600" height="200" strokeWidth="3" strokeLinecap="round" />
                <use className="wind" xlinkHref="#wind-two" x="200" y="250" width="600" height="200" strokeWidth="3" strokeLinecap="round" />
            </svg>
        </div>
    );
}
