import { Ctx } from 'boardgame.io';
import { GameState } from 'components/game/Game';
import 'components/board-state-container/BoardStateContainer.css';
import { useEffect, useState } from 'react';
import { CardObjectType, CardType } from 'models/Card';
import { GameHelper } from 'helpers/GameHelper';
import { ANSWER_REVEAL_DELAY, FOOTER_TYPE_SPEED, GAME_COUNTDOWN_TIMER_INTERVAL, RENEWABLE_ENERGY_TILE, SHUFFLE_TIME } from 'constants/GameData';
import { squareData } from 'data/square.data';
import useAnimation from 'custom-hooks/useAnimation';
import Token from 'components/token/Token';
import { OptionsObjectType, QuestionnaireOption } from 'models/Questionnaires';
import WinnerMessage from 'components/winner-message/WinnerMessage';
import CountdownTimer from 'components/countdown-timer/CountdownTimer';
import WelcomeMessage from 'components/welcome-message/WelcomeMessage';
import { cardData } from 'data/card.data';
import { STARTING_TILE } from './../../constants/GameData';
import LoadingBar from 'components/loading-bar/LoadingBar';
import { AudioHelper } from 'helpers/AudioHelper';
import Wind from 'components/wind/Wind';
import Electric from 'components/electric-sparks/Electric';

interface BoardProps {
    G: GameState;
    ctx: Ctx;
    moves: any;
    events: any;
}

const BoardStateContainer = ({ G, ctx, moves, events }: BoardProps) => {
    const [deck, setDeck] = useState<CardObjectType[]>([]);
    const [isBackgroundMusicOn, setIsBackgroundMusicOn] = useState(true);
    const [gameStarted, setGameStarted] = useState(false);
    const [isLogSubmitted, setIsLogSubmitted] = useState<boolean>(false);
    const [isAnswerSubmitted, setIsAnswerSubmitted] = useState<boolean>(false);
    const [prevComputerTokenIndex, setPrevComputerTokenIndex] = useState(G.computerTokenPosition);
    const [prevHumanTokenIndex, setPrevHumanTokenIndex] = useState(G.humanTokenPosition);
    const [cardSelected, setCardSelected] = useState(true);
    const [isGameOver, setIsGameOver] = useState(false);
    const [tempDeck, setTempDeck] = useState<CardObjectType[]>([]);
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [footerText, setFooterText] = useState('');
    const [electricWidth1, setElectricWidth1] = useState(20);
    const [electricWidth2, setElectricWidth2] = useState(20);
    const [showMove, setShowMove] = useState(false);
    const [backgroundMusicToggleable, setBackgroundMusicToggleable] = useState(false);
    const [humanToken, setHumanToken] = useState('');
    const [computerToken, setComputerToken] = useState('');
    useAnimation({
        G,
        ctx,
        deck,
        prevHumanTokenIndex,
        prevComputerTokenIndex,
        setPrevHumanTokenIndex,
        setPrevComputerTokenIndex,
        moves,
        setDeck,
        isGameOver,
        footerText,
        gameStarted,
        setIsGameOver,
        setFooterText,
        setCardSelected,
        setIsAnswerSubmitted,
        isLoaded,
        electricWidth1,
        setElectricWidth1,
        electricWidth2,
        setElectricWidth2,
        setShowMove,
    });

    function typeWriter(index: number) {
        if (index < footerText.length) {
            document.getElementById('footer-container')!.innerHTML += footerText.charAt(index);
            setTimeout(() => typeWriter(index + 1), FOOTER_TYPE_SPEED);
        }
    }

    function toggleBackgroundMusic() {
        if (backgroundMusicToggleable) {
            setIsBackgroundMusicOn((prev) => !prev);
            AudioHelper.toggleSound(AudioHelper.game_music);
        }
    }

    useEffect(() => {
        GameHelper.displayStartTextHorizontally();
        const loadBarEl = document.getElementById('load-bar');
        const images = cardData.filter((y) => y.Type === CardType.Bolt).map((x) => x.ImageUrl);
        let count = [];
        images.forEach((element) => {
            const img = new Image();
            img.src = element;
            img.onload = () => {
                count.push(img);
                if (count.length === images.length - 1) {
                    loadBarEl!.style.width = '100%';
                    document.getElementById('loading-bar-container')?.classList.add('load-bar-container');
                    setIsLoaded(true);
                    var els = document.getElementsByTagName('img');
                    for (var i = 0; i < els.length; i++) {
                        els[i].setAttribute('draggable', 'false');
                    }
                } else {
                    loadBarEl!.style.width = Number(loadBarEl!.style.width.split('%')[0]) + 100 / images.length + '%';
                }
            };
        });
    }, []);

    useEffect(() => {
        if (gameStarted) {
            triggerGameStartCountdown('game-timer', ['hidden'], events);
            setTimeout(() => {
                AudioHelper.playSound(AudioHelper.Start_Game_Countdown);
            }, 1000);
        }
    }, [gameStarted]);

    useEffect(() => {
        const arrow = document.getElementById('arrow') as HTMLDivElement;
        const midTile = document.getElementById(`mid-renewable-tile`)!;
        if (!cardSelected && !isGameOver) {
            if (midTile.classList.contains('heartBeat')) {
                setTimeout(() => {
                    arrow.classList.add('appear', 'bounce-arrow');
                    setFooterText(GameHelper.userTurn());
                }, 2000);
            } else {
                arrow.classList.add('appear', 'bounce-arrow');
                setFooterText(GameHelper.userTurn());
            }
        } else {
            arrow.classList.remove('appear');
        }
    }, [cardSelected]);

    useEffect(() => {
        if (![STARTING_TILE, RENEWABLE_ENERGY_TILE].includes(prevHumanTokenIndex)) {
            document.getElementById('human-token')?.classList.add('token-bounce');
        }
    }, [prevHumanTokenIndex]);

    useEffect(() => {
        if (deck.length === 0 && isLogSubmitted && !isGameOver) {
            setTimeout(
                () => {
                    setDeck(G.deck.Cards);
                    setTempDeck(G.deck.Cards);
                    const element = document.getElementById('deck-container');
                    if (element && element?.children?.length > 0) {
                        console.log('shuffling');
                        setTimeout(() => {
                            setBackgroundMusicToggleable(true);
                            AudioHelper.playSound(AudioHelper.game_music);
                            AudioHelper.playSound(AudioHelper.Shuffling_Cards);
                            setFooterText(GameHelper.shuffleMessage);
                            // document.getElementById('footer-container')!.innerText = gameStarted ? GameHelper.shuffleMessage : '';
                            if (gameStarted) {
                                document.getElementById('footer-container')!.classList.add('loading-text-white');
                            }
                            // AudioHelper.playSound('shuffling_cards_2');
                            Array.from(element.children).forEach((child, index) => {
                                const tempChild = child as HTMLElement;
                                setTimeout(() => {
                                    tempChild.style.transform = `translate(${0.25 * index}px, -${0.25 * index}px)`;
                                    if (element.children.length === index + 1 && !gameStarted) {
                                        setTimeout(() => {
                                            setGameStarted(true);
                                            setFooterText('');
                                        }, 500);
                                    }
                                }, index * (SHUFFLE_TIME / element.children.length));
                            });
                        }, 100);
                    }
                },
                gameStarted ? 3000 : 0
            );
        }
    }, [deck, isLogSubmitted]);

    const triggerGameStartCountdown = (id: string, classes: string[], events: any) => {
        const divTag = document.getElementById(id)! as HTMLParagraphElement;
        if (divTag) {
            divTag.classList.remove(...classes);
            divTag.classList.add('timer-fade');
            setTimeout(() => {
                divTag.classList.add(...classes);
                events?.endPhase();
                setCardSelected(false);
                document.getElementById('human-token')!.classList.remove('token-scale-0');
                document.getElementById('computer-token')!.classList.remove('token-scale-0');
                setFooterText(GameHelper.userTurn());
                GameHelper.displayStartTextVertically();
            }, GAME_COUNTDOWN_TIMER_INTERVAL);
        }
    };

    const handleCardClick = () => {
        const element = document.getElementById('top-card');
        if (element && !isGameOver && !cardSelected && footerText === GameHelper.userTurn()) {
            setCardSelected(true);
            const playedCard = deck[0];
            setFooterText(GameHelper.playerPlayedCardLog(0, playedCard.Type));
            AudioHelper.playSound(AudioHelper.Card_Flip);
            element.style.transform = 'translate(-400px, -250px) scale(2.75)';
            element?.classList.add('selected-card');
            document.getElementById('board-container')?.classList.add('blur-on');
            if (deck.length > 0 && deck[0].Type === CardType.Bolt) {
                setTimeout(() => {
                    AudioHelper.playSound(AudioHelper.Card_Disappear);
                    element.style.transform = 'translate(-400px, 500px) scale(0)';
                    document.getElementById('board-container')?.classList.remove('blur-on');
                    setTimeout(() => {
                        element?.classList.remove('selected-card');
                    }, 250);
                    setTimeout(() => {
                        element.style.transition = `none`;
                        (document.getElementById('card-type-img')! as HTMLImageElement).src = deck[1]?.Type === CardType.Bolt ? '/images/cards/bolt-cards/bolt-card-back.png' : '/images/cards/say-watt-cards/say-watt-card-back.png';
                    }, 900);
                    setTimeout(() => {
                        GameHelper.playedCardAnimationOn(playedCard);
                        moves.playCard();
                        const tempDeck = deck.filter((x, i) => i !== 0);
                        element.style.transform = `translate(${0.25 * (tempDeck.length - 1)}px, -${0.25 * (tempDeck.length - 1)}px)`;
                        setTimeout(() => {
                            setDeck(tempDeck);
                            setTimeout(() => {
                                GameHelper.playedCardAnimationOff(playedCard);
                            }, 1000);
                            // element.style.transform = `translate(${(tempDeck.length-1) % 2 === 0 ? -400 + 0.25 * (tempDeck.length-1) : 400 + 0.25 * (tempDeck.length-1)}px, ${-(-400 + 0.25 * (tempDeck.length-1))}px)`
                            // element.style.transform = `translate(${0.25 * (tempDeck.length-1)}px, -${0.25 * (tempDeck.length-1)}px)`;
                            // element.style.transition = `1s`;
                        }, 1000);
                    }, 1000);
                    setFooterText(GameHelper.playerMoveLog(0, playedCard, false, prevHumanTokenIndex));
                }, 6000);
            }
        }
    };

    const handleAnswerSubmit = (answer: number) => {
        if (!isAnswerSubmitted) {
            AudioHelper.playSound(AudioHelper.Say_Watt__Answer_Selection);
            setIsAnswerSubmitted(true);
            const element = document.getElementById('top-card');
            const correctAnswer = deck[0].Questionnaire?.Options.findIndex((opt: OptionsObjectType) => !!opt.IsAnswer);
            document.getElementById('option-' + answer)!.classList.add('selected-answer');
            document.getElementById('option-container')?.classList.add('no-hover');
            setTimeout(() => {
                document.getElementById('option-' + answer)!.classList.remove('selected-answer');

                if (answer !== correctAnswer && !isGameOver) {
                    document.getElementById('option-' + answer)?.classList.add('incorrect-answer');
                    document.getElementById('question-card')?.classList.add('shakeX');
                    AudioHelper.playSound(AudioHelper.Incorrect_Answer);
                } else {
                    document.getElementById('say-watt-text')!.style.opacity = '0';
                    setTimeout(() => {
                        setShowMove(true);
                    }, 500);
                    setTimeout(() => {
                        if (document.getElementById('move-text')) {
                            document.getElementById('move-text')!.style.opacity = '1';
                        }
                    }, 550);
                    document.getElementById('question-card')?.classList.add('heartbeat-without-brightness');
                    AudioHelper.playSound(AudioHelper.Correct_Answer);
                }
                setFooterText(GameHelper.playerAnsweredQuizCardLog(0, answer === correctAnswer));
                document.getElementById('option-container')?.classList.add('answered');
                if (element && deck.length > 0 && deck[0].Type === CardType.SayWatt && !isGameOver) {
                    setTimeout(() => {
                        setFooterText(GameHelper.playerMoveLog(0, G.deck.Cards[0], answer === correctAnswer));
                        AudioHelper.playSound(AudioHelper.Card_Disappear);
                        element.style.transform = 'translate(-400px, 500px) scale(0)';
                        document.getElementById('option-container')?.classList.remove('no-hover');
                        setIsAnswerSubmitted(false);
                        document.getElementById('board-container')?.classList.remove('blur-on');
                        setTimeout(() => {
                            element?.classList.remove('selected-card');
                        }, 250);
                        setTimeout(() => {
                            element.style.transition = `none`;
                            setShowMove(false);
                            if (document.getElementById('say-watt-text')) {
                                document.getElementById('say-watt-text')!.style.opacity = '1';
                            }
                            if (document.getElementById('move-text')) {
                                document.getElementById('move-text')!.style.opacity = '0';
                            }
                            document.getElementById('question-card')?.classList.remove('shakeX');
                            document.getElementById('question-card')?.classList.remove('heartbeat-without-brightness');
                            document.getElementById('option-' + answer)?.classList.remove('incorrect-answer');
                            document.getElementById('option-container')?.classList.remove('answered');
                            (document.getElementById('card-type-img')! as HTMLImageElement).src = deck[1].Type === CardType.Bolt ? '/images/cards/bolt-cards/bolt-card-back.png' : '/images/cards/say-watt-cards/say-watt-card-back.png';
                        }, 900);
                        setTimeout(() => {
                            if (answer === correctAnswer && deck[0].CardId !== '33') {
                                GameHelper.playedCardAnimationOn(deck[0]);
                            }
                            moves.playCard(answer);
                            const tempDeck = deck.filter((x, i) => i !== 0);
                            element.style.transform = `translate(${0.25 * (tempDeck.length - 1)}px, -${0.25 * (tempDeck.length - 1)}px)`;
                            setTimeout(() => {
                                setDeck(tempDeck);
                                if (answer === correctAnswer) {
                                    setTimeout(() => {
                                        GameHelper.playedCardAnimationOff(deck[0]);
                                    }, 1000);
                                }
                                // element.style.transform = `translate(${(tempDeck.length-1) % 2 === 0 ? -400 + 0.25 * (tempDeck.length-1) : 400 + 0.25 * (tempDeck.length-1)}px, ${-(-400 + 0.25 * (tempDeck.length-1))}px)`
                                // element.style.transform = `translate(${0.25 * (tempDeck.length-1)}px, -${0.25 * (tempDeck.length-1)}px)`;
                                // element.style.transition = `1s`;
                            }, 1000);
                        }, 1000);
                    }, 3000);
                }
            }, ANSWER_REVEAL_DELAY);
        }
    };

    useEffect(() => {
        if (footerText) {
            document.getElementById('footer-container')!.innerText = '';
            typeWriter(0);
        }
    }, [footerText]);

    return (
        <>
            <div className={`absolute flex justify-around w-full shadow-lg grayscale-100 overflow-hidden ${isLogSubmitted ? '' : 'pointer-events-none'}`} style={{ zIndex: 2000, top: '0', bottom: '54px', left: '0', right: '0' }}>
                <div
                    className="deck-container flex w-44 h-56"
                    id="deck-container"
                    style={{
                        position: 'absolute',
                        top: '500px',
                        left: '940px',
                    }}
                >
                    {deck
                        .map((x: CardObjectType) => x)
                        .reverse()
                        .map(
                            (card: CardObjectType, idx: number) =>
                                idx !== deck.length - 1 && (
                                    <img
                                        className={`absolute w-40 card ${idx === 0 ? '' : ' card-less-shadow'} duration-1000`}
                                        alt="deck"
                                        draggable="false"
                                        key={idx}
                                        src={card.Type === CardType.Bolt ? '/images/cards/bolt-cards/bolt-card-back.png' : '/images/cards/say-watt-cards/say-watt-card-back.png'}
                                        id={'deck-card-' + (idx + 1)}
                                        style={
                                            idx === 0
                                                ? {
                                                      boxShadow: 'rgb(0 0 0 / 60%) -10px 0px 30px',
                                                      transform: `translate(${idx % 2 === 0 ? -400 + 0.25 * idx : 400 + 0.25 * idx}px, ${-(-400 + 0.25 * idx)}px)`,
                                                      transition: `all ${(SHUFFLE_TIME - 1000) / 1000 / deck.length + 0.6}s`,
                                                      zIndex: 800 + idx,
                                                  }
                                                : {
                                                      transform: `translate(${idx % 2 === 0 ? -400 + 0.25 * idx : 400 + 0.25 * idx}px, ${-(-400 + 0.25 * idx)}px)`,
                                                      transition: `all ${(SHUFFLE_TIME - 1000) / 1000 / deck.length + 0.6}s`,
                                                      zIndex: 800 + idx,
                                                  }
                                        }
                                    />
                                )
                        )}

                    {deck.length > 0 && (
                        <div
                            className={`flip-card w-40 card card-less-shadow top-card ${deck.length === 1 ? 'single' : ''} `}
                            id="top-card"
                            onClick={(e) => {
                                e.stopPropagation();
                                if (!cardSelected) {
                                    handleCardClick();
                                }
                            }}
                            // onClick={cardSelected? null : handleCardClick}
                            style={{
                                zIndex: 800 + deck.length + 1,
                                transform: `translate(${(tempDeck.length - 1) % 2 === 0 ? -400 + 0.25 * (tempDeck.length - 1) : 400 + 0.25 * (tempDeck.length - 1)}px, ${-(-400 + 0.25 * (tempDeck.length - 1))}px)`,
                                transition: `all ${(SHUFFLE_TIME - 1000) / 1000 / tempDeck.length + 0.6}s`,
                            }}
                        >
                            <div className={'flip-card-inner duration-1000 ' + (!cardSelected && !isGameOver && footerText === GameHelper.userTurn() ? 'cursor-pointer' : '')}>
                                <div className="flip-card-front">
                                    <img id="card-type-img" draggable="false" alt="computer-cards-front" src={deck[0].Type === CardType.Bolt ? '/images/cards/bolt-cards/bolt-card-back.png' : '/images/cards/say-watt-cards/say-watt-card-back.png'} />
                                </div>
                                <div className="flip-card-back">
                                    {deck[0].Type === CardType.Bolt && (
                                        <img
                                            alt="computer-cards-back"
                                            id="top-card-img"
                                            draggable="false"
                                            src={deck[0].ImageUrl}
                                            // src="/images/cards/say-watt-cards/say-watt-card-back.png"
                                        />
                                    )}
                                    {deck[0].Type === CardType.SayWatt && (
                                        <div
                                            className="empty-card-container px-3"
                                            id="question-card"
                                            style={{
                                                background: `url(/images/cards/empty_card.png)`,
                                                backgroundRepeat: 'no-repeat',
                                                backgroundSize: 'cover',
                                                width: '160px',
                                                height: '224px',
                                                fontFamily: 'Calps Slim',
                                                fontStyle: 'italic',
                                                color: 'rgb(234, 86, 50)',
                                            }}
                                        >
                                            {showMove && (
                                                <div id="move-text" className="mt-3 mb-2 flex justify-center items-center" style={{ opacity: '0', transition: 'opacity 0.5s', minHeight: '25px', maxHeight: '25px' }}>
                                                    <div
                                                        style={{
                                                            fontFamily: 'Greycliff CF',
                                                            fontStyle: 'italic',
                                                            letterSpacing: '1px',
                                                            fontSize: '65%',
                                                            marginTop: '-5px',
                                                            marginRight: '3px',
                                                        }}
                                                    >
                                                        MOVE FORWARD
                                                    </div>
                                                    <div
                                                        style={{
                                                            fontSize: '130%',
                                                        }}
                                                    >
                                                        {deck[0].MoveValue === 1 ? 'ONE' : deck[0].MoveValue === 2 ? 'TWO' : 'THREE'}
                                                    </div>
                                                </div>
                                            )}
                                            {!showMove && (
                                                <div id="say-watt-text" className="mt-3 mb-2 flex justify-around items-center" style={{ opacity: '1', transition: 'opacity 0.5s', minHeight: '25px', maxHeight: '25px' }}>
                                                    <div
                                                        style={{
                                                            fontSize: '130%',
                                                            marginTop: '-5px',
                                                        }}
                                                    >
                                                        Say Watt?
                                                    </div>
                                                </div>
                                            )}
                                            <div style={{ fontSize: '70%', textAlign: 'left' }}>{deck[0].Questionnaire?.Question}</div>
                                            <div style={{ fontSize: '70%', textAlign: 'left' }} id={'option-container'} className="mt-4 option-container">
                                                {deck[0].Questionnaire?.Options.map((option: QuestionnaireOption, idx: number) => (
                                                    <div style={{ textShadow: 'rgb(0 0 0 / 40%) 1px 1px 3px' }} key={idx} id={'option-' + idx} className={option.IsAnswer ? ' correct-answer option-hover ' : ' option-hover '} onClick={() => handleAnswerSubmit(idx)}>
                                                        {String.fromCharCode(65 + idx) + ') '}
                                                        {option.Title}
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                </div>

                <Wind id="wind" style={{ width: '900px', height: '470px', transform: 'rotateY(180deg) rotateZ(25deg) translate(440px, 115px)', position: 'absolute', opacity: '0', transition: 'opacity 0.5s' }} />
                <Token
                    id="human-token"
                    className="absolute move-square blur-8 token-scale-0"
                    style={{
                        top: `${squareData[prevHumanTokenIndex].y}px`,
                        left: `${prevComputerTokenIndex === prevHumanTokenIndex ? squareData[prevHumanTokenIndex].x + 10 : squareData[prevHumanTokenIndex].x}px`,
                        // boxShadow: 'rgb(0 0 0 / 50%) -5px 5px 15px',
                        boxShadow: 'rgb(0 0 0 / 100%) -2px 4px 5px',
                        width: '3.75rem',
                        zIndex: 602,
                        borderRadius: '50px',
                        transition: 'left 0.5s ease-in-out, top 0.5s ease-in-out, filter 1s, transform 0.5s, box-shadow 0.5s, opacity 0.5s',
                        // transition: 'all 0.5s'
                    }}
                    alt="human-token"
                    src={`/images/tokens/${humanToken}.png`}
                />

                <div id="blackout-overlay" className="absolute w-full h-full duration-1000"></div>
                <img src="/images/Artboard 101.png" id="text-bubble" className="relative inline" style={{ position: 'absolute', zIndex: 600, width: '250px', top: '246px', right: '298px', transform: 'scale(0)' }} alt="text-bubble" />
                <img src="/images/Text_bubble.svg" id="short-circuit-text-bubble" className="inline blur-8" style={{ position: 'absolute', zIndex: 600, width: '70px', transform: 'scale(0)' }} alt="short-circuit-text-bubble" />
                <Token
                    id="computer-token"
                    className="absolute move-square blur-8 token-scale-0"
                    style={{
                        top: `${squareData[prevComputerTokenIndex].y}px`,
                        left: `${prevComputerTokenIndex === prevHumanTokenIndex ? squareData[prevComputerTokenIndex].x - 10 : squareData[prevComputerTokenIndex].x}px`,
                        // boxShadow: 'rgb(0 0 0 / 50%) -5px 5px 15px',
                        boxShadow: 'rgb(0 0 0 / 100%) -2px 4px 5px',
                        width: '3.75rem',
                        zIndex: 601,
                        borderRadius: '50px',
                        transition: 'left 0.5s ease-in-out, top 0.5s ease-in-out, filter 1s, transform 0.5s, box-shadow 0.5s, opacity 0.5s',
                        // transition: 'all 0.5s'
                    }}
                    alt="computer-token"
                    src={`/images/tokens/${computerToken}.png`}
                />

                <CountdownTimer />
                <WinnerMessage setFooterText={setFooterText} won={ctx.gameover?.winner === '0'} appear={isGameOver} />
                <div
                    className="relative overflow-hidden m-auto w-full blur-8"
                    style={{
                        background: `url(/images/frame_layout.png)`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'contain',
                        // backgroundColor: '#39b54a',
                        maxWidth: '1200px',
                        minWidth: '1200px',
                        minHeight: '776.493px',
                        zIndex: 900,
                        maxHeight: '776.493px',
                        pointerEvents: 'none',
                    }}
                ></div>
                {/* <img className="absolute" style={{top: "462px", left: "84px", boxShadow: 'rgb(0 0 0 / 7%) -1px 1px 2px', width: '3rem'}} alt="computer-token" src="/images/tokens/Exceptional-Energy.png" />
                <img className="absolute" style={{top: "467px", left: "75px", boxShadow: 'rgb(0 0 0 / 7%) -1px 1px 2px', width: '3rem'}} alt="computer-token" src="/images/tokens/Exceptional-Energy.png" /> */}
            </div>

            {!isLogSubmitted && (
                <div className="flex justify-center items-center h-full w-full">
                    <WelcomeMessage setComputerToken={setComputerToken} setHumanToken={setHumanToken} onSubmitCompletion={() => setIsLogSubmitted(true)} showPopup={isLoaded} G={G} />
                </div>
            )}
            <LoadingBar />
            <div
                id="footer-container"
                className="p-3 text-center text-white"
                style={{
                    textShadow: '2px 2px 5px #000',
                    minHeight: '54px',
                    fontStyle: 'italic',
                    fontFamily: 'Greycliff CF',
                    fontSize: '20px',
                    backgroundColor: 'rgba(58,58,58,0.7)',
                    position: 'absolute',
                    bottom: '0',
                    left: '0',
                    right: '0',
                }}
            ></div>
            <div id="bolt-container" className="absolute" style={{ opacity: 0, width: '3.75rem', height: '3.75rem', zIndex: 999 }}>
                <div id="bolt-1" className="absolute" style={{ clipPath: 'polygon(54% 0, 83% 0, 57% 37%, 75% 37%, 28% 99%, 41% 47%, 24% 47%)', backgroundColor: 'yellow', width: '40px', height: '40px', opacity: 1, zIndex: 999, transform: 'rotateZ(123deg)' }}></div>
                <div id="bolt-2" className="absolute" style={{ clipPath: 'polygon(54% 0, 83% 0, 57% 37%, 75% 37%, 28% 99%, 41% 47%, 24% 47%)', backgroundColor: 'yellow', width: '40px', height: '40px', opacity: 1, zIndex: 999, transform: 'rotateZ(183deg)' }}></div>
                <div id="bolt-3" className="absolute" style={{ clipPath: 'polygon(54% 0, 83% 0, 57% 37%, 75% 37%, 28% 99%, 41% 47%, 24% 47%)', backgroundColor: 'yellow', width: '40px', height: '40px', opacity: 1, zIndex: 999, transform: 'rotateZ(10deg)' }}></div>
                <div id="bolt-4" className="absolute" style={{ clipPath: 'polygon(54% 0, 83% 0, 57% 37%, 75% 37%, 28% 99%, 41% 47%, 24% 47%)', backgroundColor: 'yellow', width: '40px', height: '40px', opacity: 1, zIndex: 999, transform: 'rotateZ(-58deg)' }}></div>
            </div>
            <img
                onClick={toggleBackgroundMusic}
                className={`absolute ${!backgroundMusicToggleable ? 'blur-8' : ''} ${backgroundMusicToggleable ? 'cursor-pointer' : ''}`}
                style={{
                    width: '55px',
                    backgroundColor: '#218690',
                    zIndex: 10000,
                    bottom: '75px',
                    left: '25px',
                    boxShadow:
                        '0px 1px 2px #eee, 0px 2px 2px rgb(33 134 144), 0px 3px 2px rgb(33 134 144), 0px 4px 2px rgb(33 134 144), 0px 5px 2px rgb(33 134 144), 0px 6px 2px rgb(33 134 144), 0px 7px 2px rgb(33 134 144), 0px 7px 2px rgb(33 134 144 / 50%), 0px 7px 2px rgb(33 134 144 / 10%), 0px 7px 2px rgb(33 134 144 / 73%), 0px 3px 5px rgb(33 134 144 / 30%), 0px 5px 10px rgb(33 134 144 / 37%), 0px 10px 10px rgb(33 134 144 / 10%), 0px 20px 20px rgb(33 134 144 / 10%)',
                }}
                src={isBackgroundMusicOn ? 'images/Sound_on_-_yellow_icon.svg' : 'images/Sound_off_-_white_icon.svg'}
                alt="sound-button"
            />
            <Electric electricWidth={electricWidth1} setElectricWidth={setElectricWidth1} className="flex justify-end absolute electric-svg" id="electric-div-1" style={{ width: '410px', height: '45px', zIndex: 999, top: '290px', left: '248px', transform: 'rotateZ(-3deg)' }} />

            <Electric electricWidth={electricWidth2} setElectricWidth={setElectricWidth2} className="flex justify-start absolute electric-svg" id="electric-div-2" style={{ width: '410px', height: '45px', zIndex: 999, top: '510px', left: '297px', transform: 'rotateZ(-23deg)' }} />
        </>
    );
};

export default BoardStateContainer;
