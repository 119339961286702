import 'components/windmill/Windmill.css';
import { GameHelper } from 'helpers/GameHelper';

export default function Windmill({ containerStyles, poleStyles, fanStyles, className }: any) {
    return (
        <div className={`absolute windmill ${className ? className : ''}`} style={{ ...containerStyles, width: '12px', zIndex: 'unset' }}>
            <img
                className="absolute blade z-10 blur-8 fan-img"
                src={'/images/windmill_propeller_293x.png'}
                alt="fan-blades"
                style={{
                    ...fanStyles,
                    maxWidth: 'unset',
                    animationTimingFunction: 'linear',
                    animationIterationCount: 'infinite',
                    animationName: 'rotation',
                    animationDuration: `${GameHelper.randomGenerator(4, 7)}s`,

                    // zIndex: 10001
                    // filter: 'drop-shadow(black 7px 4px 14px)',
                }}
            />
            <img className="absolute pole blur-8" style={poleStyles} src={'/images/windmill_stand_303x.png'} alt="fan-pole" />
        </div>
    );
}
