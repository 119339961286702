import { Ctx } from 'boardgame.io';
import { GameState } from 'components/game/Game';
import { DC_TO_AC_MOVEMENT_DURATION, TOKEN_SQUARE_MOVEMENT_DURATION } from 'constants/AnimationData';
import { ANSWER_REVEAL_DELAY, ENDING_TILE, FIRST_AC_TILE, FIRST_DC_TILE, FIRST_SOLAR_PANEL, FIRST_WIND_TURBINE, PEAK_LOAD_PANIC_TILE, RENEWABLE_ENERGY_TILE, SECOND_AC_TILE, SECOND_DC_TILE, SECOND_SOLAR_PANEL, SECOND_WIND_TURBINE, STARTING_TILE, TOKEN_SHAKE_DURATION } from 'constants/GameData';
import { cardData } from 'data/card.data';
import { squareData } from 'data/square.data';
import { AudioHelper } from 'helpers/AudioHelper';
import { GameHelper } from 'helpers/GameHelper';
import { CardObjectType, CardType } from 'models/Card';
import { OptionsObjectType } from 'models/Questionnaires';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';

interface IUseAnimation {
    G: GameState;
    ctx: Ctx;
    prevComputerTokenIndex: number;
    setPrevComputerTokenIndex: Function;
    prevHumanTokenIndex: number;
    setPrevHumanTokenIndex: Function;
    moves: any;
    deck: CardObjectType[];
    setDeck: Function;
    gameStarted: boolean;
    isLoaded: boolean;
    setCardSelected: Function;
    isGameOver: boolean;
    setIsGameOver: Function;
    setIsAnswerSubmitted: Function;
    setFooterText: Function;
    footerText: string;
    electricWidth1: number;
    setElectricWidth1: Dispatch<SetStateAction<number>>;
    electricWidth2: number;
    setElectricWidth2: Dispatch<SetStateAction<number>>;
    setShowMove: Function;
}

const shortCircuitOpponent = (G: GameState, currentPlayerTokenId: string, setOpponentPrevTokenIndex: Function, afterTime: number, setFooterText: Function) => {
    if (currentPlayerTokenId === 'human-token') {
        setTimeout(() => {
            setOpponentPrevTokenIndex((prev: number) => {
                if (prev === G.humanTokenPosition) {
                    // setFooterText(GameHelper.playerShortCircuitLog(0));
                    const textBubble = document.getElementById('short-circuit-text-bubble')!;
                    textBubble.style.top = squareData[G.humanTokenPosition].y.toString() + 'px';
                    textBubble.style.left = squareData[G.humanTokenPosition].x.toString() + 'px';
                    const boltContainer = document.getElementById('bolt-container')!;
                    textBubble.style.top = (squareData[G.humanTokenPosition].y - 62).toString() + 'px';
                    textBubble.style.left = (squareData[G.humanTokenPosition].x + 57).toString() + 'px';
                    textBubble.classList.add('textBubbleFadeInAndOut');
                    boltContainer.classList.add('fadeInAndOut');
                    boltContainer.style.top = squareData[G.humanTokenPosition].y.toString() + 'px';
                    boltContainer.style.left = squareData[G.humanTokenPosition].x.toString() + 'px';
                    for (let i = 0; i < 4; i++) {
                        const bolt = document.getElementById(`bolt-${i + 1}`)!;
                        bolt.style.top = '0px';
                        bolt.style.left = '0px';
                        bolt.style.transition = 'top 2s linear, left 2s linear';
                        if (i === 0) {
                            bolt.style.top = '-15px';
                            bolt.style.left = '-20px';
                        } else if (i === 1) {
                            bolt.style.top = '-20px';
                            bolt.style.left = '35px';
                        } else if (i === 2) {
                            bolt.style.top = '45px';
                            bolt.style.left = '-16px';
                        } else {
                            bolt.style.top = '45px';
                            bolt.style.left = '40px';
                        }
                        setTimeout(() => {
                            bolt.style.transition = 'unset';
                            boltContainer.classList.remove('fadeInAndOut');
                        }, 2000);
                        setTimeout(() => {
                            textBubble.classList.remove('textBubbleFadeInAndOut');
                        }, 4000);
                    }
                }
                return prev;
            });
        }, afterTime);
    } else {
        setTimeout(() => {
            setOpponentPrevTokenIndex((prev: number) => {
                if (prev === G.computerTokenPosition) {
                    // setFooterText(GameHelper.playerShortCircuitLog(1));
                    const textBubble = document.getElementById('short-circuit-text-bubble')!;
                    textBubble.style.top = squareData[G.computerTokenPosition].y.toString() + 'px';
                    textBubble.style.left = squareData[G.computerTokenPosition].x.toString() + 'px';
                    const boltContainer = document.getElementById('bolt-container')!;
                    textBubble.style.top = (squareData[G.computerTokenPosition].y - 62).toString() + 'px';
                    textBubble.style.left = (squareData[G.computerTokenPosition].x + 57).toString() + 'px';
                    textBubble.classList.add('textBubbleFadeInAndOut');
                    boltContainer.classList.add('fadeInAndOut');
                    boltContainer.style.top = squareData[G.computerTokenPosition].y.toString() + 'px';
                    boltContainer.style.left = squareData[G.computerTokenPosition].x.toString() + 'px';
                    for (let i = 0; i < 4; i++) {
                        const bolt = document.getElementById(`bolt-${i + 1}`)!;
                        bolt.style.top = '0px';
                        bolt.style.left = '0px';
                        bolt.style.transition = 'top 2s linear, left 2s linear';
                        if (i === 0) {
                            bolt.style.top = '-15px';
                            bolt.style.left = '-20px';
                        } else if (i === 1) {
                            bolt.style.top = '-20px';
                            bolt.style.left = '35px';
                        } else if (i === 2) {
                            bolt.style.top = '45px';
                            bolt.style.left = '-16px';
                        } else {
                            bolt.style.top = '45px';
                            bolt.style.left = '40px';
                        }
                        setTimeout(() => {
                            bolt.style.transition = 'unset';
                            boltContainer.classList.remove('fadeInAndOut');
                        }, 2000);
                        setTimeout(() => {
                            textBubble.classList.remove('textBubbleFadeInAndOut');
                        }, 4000);
                    }
                }
                return prev;
            });
        }, afterTime);
    }
};

const triggerTokenPositionChange = (
    G: GameState,
    from: number,
    to: number,
    setPrevPlayerTokenIndex: Function,
    setOpponentPrevTokenIndex: Function,
    setHumanMovesCompleted: Function,
    tokenId: string,
    isGameOver: boolean,
    setIsGameOver: Function,
    setFooterText: Function,
    electricWidth1: number,
    setElectricWidth1: Dispatch<SetStateAction<number>>,
    electricWidth2: number,
    setElectricWidth2: Dispatch<SetStateAction<number>>,
    setAfterComputerMoveFinished?: Function
) => {
    if (G.shouldComputerShake && tokenId === 'computer-token') {
        const computerToken = document.getElementById('computer-token');
        computerToken?.classList.add('shakeX');
        setTimeout(() => {
            computerToken?.classList.remove('shakeX');
        }, TOKEN_SHAKE_DURATION);
    }
    const goingBack = from > to;
    var flag = false;
    for (let index = 0; (!goingBack && index <= to - (from + 1)) || (goingBack && index <= from - 1 - to); index++) {
        if (tokenId === 'human-token') {
            flag = true;
        }
        setTimeout(() => {
            console.log(index * TOKEN_SQUARE_MOVEMENT_DURATION);
            let currentPosition = goingBack ? from - 1 - index : ((from + 1 + index) > ENDING_TILE ? ENDING_TILE : (from + 1 + index) );
            setPrevPlayerTokenIndex(currentPosition);
            if (to === FIRST_SOLAR_PANEL && currentPosition === FIRST_SOLAR_PANEL) {
                setTimeout(() => {
                    document.getElementById('human-token')?.classList.remove('token-bounce');
                    document.getElementById('computer-token')?.classList.remove('token-bounce');
                }, 500);
                solarPanelSlide(G, tokenId, setHumanMovesCompleted, setPrevPlayerTokenIndex, setOpponentPrevTokenIndex, setFooterText, setAfterComputerMoveFinished);
            } else if (to === FIRST_DC_TILE && currentPosition === FIRST_DC_TILE) {
                setTimeout(() => {
                    document.getElementById('human-token')?.classList.remove('token-bounce');
                    document.getElementById('computer-token')?.classList.remove('token-bounce');
                }, 500);
                setTimeout(() => {
                    dcToAcTeleport(G, tokenId, to, setPrevPlayerTokenIndex, setOpponentPrevTokenIndex, setHumanMovesCompleted, setFooterText, electricWidth1, setElectricWidth1, electricWidth2, setElectricWidth2, setAfterComputerMoveFinished);
                }, 1000);
            } else if (to === SECOND_DC_TILE && currentPosition === SECOND_DC_TILE) {
                setTimeout(() => {
                    document.getElementById('human-token')?.classList.remove('token-bounce');
                    document.getElementById('computer-token')?.classList.remove('token-bounce');
                }, 500);
                setTimeout(() => {
                    dcToAcTeleport(G, tokenId, to, setPrevPlayerTokenIndex, setOpponentPrevTokenIndex, setHumanMovesCompleted, setFooterText, electricWidth1, setElectricWidth1, electricWidth2, setElectricWidth2, setAfterComputerMoveFinished);
                }, 1000);
            } else if (to === FIRST_WIND_TURBINE && currentPosition === FIRST_WIND_TURBINE) {
                setTimeout(() => {
                    document.getElementById('human-token')?.classList.remove('token-bounce');
                    document.getElementById('computer-token')?.classList.remove('token-bounce');
                }, 500);
                setTimeout(() => {
                    windmillFly(G, tokenId, setHumanMovesCompleted, setPrevPlayerTokenIndex, setOpponentPrevTokenIndex, setFooterText, setAfterComputerMoveFinished);
                }, 1000);
            } else if (to === PEAK_LOAD_PANIC_TILE && currentPosition === PEAK_LOAD_PANIC_TILE) {
                setTimeout(() => {
                    document.getElementById('human-token')?.classList.remove('token-bounce');
                    document.getElementById('computer-token')?.classList.remove('token-bounce');
                }, 500);
                setTimeout(() => {
                    document.getElementById('blackout-overlay')!.classList.add('blackout');
                    setTimeout(() => {
                        document.getElementById('blackout-overlay')!.classList.remove('blackout');
                    }, 15000);
                    AudioHelper.playSound(AudioHelper.Peak_Load_Panic);
                    document.getElementById(`text-bubble`)!.classList.add('textBubbleFadeInAndOut');
                    document.getElementById(`Smoke01`)!.classList.add('peakLoadSmokeFadeInAndOut');
                    document.getElementById(`Smoke02`)!.classList.add('peakLoadSmokeFadeInAndOut');
                    document.getElementById(`Smoke03`)!.classList.add('peakLoadSmokeFadeInAndOut');
                    // AudioHelper.playSound(AudioHelper.Peak_Load_Panic);
                    setFooterText(GameHelper.arrivedAtPeakLoadPanic(tokenId === 'human-token' ? 0 : 1));
                    triggerTokenPositionChange(
                        G,
                        currentPosition,
                        tokenId === 'human-token' ? G.humanTokenPosition : G.computerTokenPosition,
                        setPrevPlayerTokenIndex,
                        setOpponentPrevTokenIndex,
                        setHumanMovesCompleted,
                        tokenId,
                        isGameOver,
                        setIsGameOver,
                        setFooterText,
                        electricWidth1,
                        setElectricWidth1,
                        electricWidth2,
                        setElectricWidth2,
                        setAfterComputerMoveFinished
                    );
                    // peakLoadPanicSlide(G, tokenId, setHumanMovesCompleted, setPrevPlayerTokenIndex, setOpponentPrevTokenIndex);
                    setTimeout(() => {
                        document.getElementById(`Smoke01`)!.classList.remove('peakLoadSmokeFadeInAndOut');
                        document.getElementById(`Smoke02`)!.classList.remove('peakLoadSmokeFadeInAndOut');
                        document.getElementById(`Smoke03`)!.classList.remove('peakLoadSmokeFadeInAndOut');
                        document.getElementById(`text-bubble`)!.classList.remove('textBubbleFadeInAndOut');
                    }, 4000);
                }, 1000);
            } else if (to === currentPosition) {
                if (to === RENEWABLE_ENERGY_TILE) {
                    setTimeout(() => {
                        setFooterText(GameHelper.arrivedOrPassedRenewableTile());
                        document.getElementById(`sun`)!.classList.add('heartBeat');
                        document.getElementById(`solar-panel-1`)!.classList.add('heartBeat');
                        document.getElementById(`solar-panel-2`)!.classList.add('heartBeat');
                        document.getElementById(`mid-renewable-tile`)!.classList.add('heartBeat');
                        document.getElementById(`circular-text`)!.classList.add('text-rotate');
                        const windmills = document.getElementsByClassName('windmill');
                        for (let i = 0; i < windmills.length; i++) {
                            windmills[i].classList.add('heartBeat');
                        }
                        setTimeout(() => {
                            document.getElementById(`sun`)!.classList.remove('heartBeat');
                            for (let i = 0; i < windmills.length; i++) {
                                windmills[i].classList.remove('heartBeat');
                            }
                            document.getElementById(`solar-panel-1`)!.classList.remove('heartBeat');
                            document.getElementById(`solar-panel-2`)!.classList.remove('heartBeat');
                            document.getElementById(`mid-renewable-tile`)!.classList.remove('heartBeat');
                            document.getElementById(`circular-text`)!.classList.remove('text-rotate');
                        }, 3000);
                    }, 1000);
                } else if (to === ENDING_TILE) {
                    setTimeout(() => {
                        document.getElementById(`sun`)!.classList.add('heartBeat');
                        document.getElementById(`solar-panel-1`)!.classList.add('heartBeat');
                        document.getElementById(`solar-panel-2`)!.classList.add('heartBeat');
                        document.getElementById(`last-renewable-tile`)!.classList.add('heartBeat');
                        document.getElementById(`last-circular-text`)!.classList.add('text-rotate');
                        const windmills = document.getElementsByClassName('windmill');
                        for (let i = 0; i < windmills.length; i++) {
                            windmills[i].classList.add('heartBeat');
                        }
                        setTimeout(() => {
                            document.getElementById(`sun`)!.classList.remove('heartBeat');
                            for (let i = 0; i < windmills.length; i++) {
                                windmills[i].classList.remove('heartBeat');
                            }
                            document.getElementById(`solar-panel-1`)!.classList.remove('heartBeat');
                            document.getElementById(`solar-panel-2`)!.classList.remove('heartBeat');
                            document.getElementById(`last-renewable-tile`)!.classList.remove('heartBeat');
                            document.getElementById(`last-circular-text`)!.classList.remove('text-rotate');
                        }, 3000);
                    }, 1000);
                }
                if (tokenId === 'human-token') {
                    shortCircuitOpponent(G, tokenId, setOpponentPrevTokenIndex, 500, setFooterText);
                    if (currentPosition === ENDING_TILE) {
                        setTimeout(() => {
                            setTimeout(() => {
                                setIsGameOver(true);
                            }, 500);
                            document.getElementById('human-token')?.classList.remove('token-bounce');
                        }, 500);
                    } else {
                        setTimeout(() => {
                            document.getElementById('human-token')?.classList.remove('token-bounce');
                            setTimeout(
                                () => {
                                    setHumanMovesCompleted(true);
                                },
                                G.humanMiddleTokenPosition === PEAK_LOAD_PANIC_TILE ? 4000 : 500
                            );
                        }, 500);
                    }
                } else {
                    setTimeout(() => {
                        document.getElementById('computer-token')?.classList.remove('token-bounce');
                    }, 500);
                    shortCircuitOpponent(G, tokenId, setOpponentPrevTokenIndex, 500, setFooterText);
                    setTimeout(() => {
                        if (currentPosition === ENDING_TILE) setIsGameOver(true);
                        setHumanMovesCompleted(false);
                        if (setAfterComputerMoveFinished) setAfterComputerMoveFinished();
                    }, 1000);
                }
            } else if (currentPosition === RENEWABLE_ENERGY_TILE) {
                setFooterText(GameHelper.arrivedOrPassedRenewableTile());
                setTimeout(() => {
                    document.getElementById(`mid-renewable-tile`)!.classList.add('heartBeat');
                    document.getElementById(`circular-text`)!.classList.add('text-rotate');
                    setTimeout(() => {
                        document.getElementById(`mid-renewable-tile`)!.classList.remove('heartBeat');
                        document.getElementById(`circular-text`)!.classList.remove('text-rotate');
                    }, 3000);
                }, 1000);
            }
        }, index * TOKEN_SQUARE_MOVEMENT_DURATION);
    }
    if (from === to && tokenId === 'computer-token' && setAfterComputerMoveFinished) {
        setAfterComputerMoveFinished();
    }
};

const solarPanelSlide = (G: GameState, tokenId: string, setHumanMovesCompleted: Function, setPlayerPrevTokenIndex: Function, setOpponentPrevTokenIndex: Function, seFooterText: Function, setAfterComputerMoveFinished?: Function) => {
    const element = document.getElementById(tokenId);
    const slide = document.getElementById('solar-slide');
    if (element) {
        setTimeout(() => {
            slide?.classList.add('heartBeat');
            document.getElementById('solar-panel-1')?.classList.add('heartBeat');
            document.getElementById('solar-panel-2')?.classList.add('heartBeat');
        }, 1000);
        setTimeout(() => {
            AudioHelper.playSound(AudioHelper.yipee);
            // seFooterText(GameHelper.playerSolarSlideMoveLog(tokenId === 'human-token' ? 0 : 1));
            element.classList.add('solar-panel-slide');
            slide?.classList.remove('heartBeat');
            document.getElementById('solar-panel-1')?.classList.remove('heartBeat');
            document.getElementById('solar-panel-2')?.classList.remove('heartBeat');
            setTimeout(() => {
                element.classList.remove('solar-panel-slide');
                setPlayerPrevTokenIndex(SECOND_SOLAR_PANEL);
                setHumanMovesCompleted(tokenId === 'human-token');
                if (setAfterComputerMoveFinished) setAfterComputerMoveFinished();
            }, 1000);
            shortCircuitOpponent(G, tokenId, setOpponentPrevTokenIndex, 2000, seFooterText);
        }, 3000);
    }
};

const windmillFly = (G: GameState, tokenId: string, setHumanMovesCompleted: Function, setPlayerPrevTokenIndex: Function, setOpponentPrevTokenIndex: Function, setFooterText: Function, setAfterComputerMoveFinished?: Function) => {
    const element = document.getElementById(tokenId);
    if (element) {
        document.getElementById('wind')!.style.opacity = '1';
        setTimeout(() => {
            AudioHelper.playSound(AudioHelper.WIND_Howl_And_Whistle);
            setFooterText(GameHelper.playerWindTurbineMoveLog(tokenId === 'human-token' ? 0 : 1));
            element.classList.add('windmill-fly');
            setTimeout(() => {
                element.classList.remove('windmill-fly');
                setPlayerPrevTokenIndex(SECOND_WIND_TURBINE);
                setHumanMovesCompleted(tokenId === 'human-token');
                document.getElementById('wind')!.style.opacity = '0';
                if (setAfterComputerMoveFinished) setAfterComputerMoveFinished();
            }, 2000);
            shortCircuitOpponent(G, tokenId, setOpponentPrevTokenIndex, 3000, setFooterText);
        }, 500);
    }
};

const dcToAcTeleport = (
    G: GameState,
    tokenId: string,
    prevTokenIndex: number,
    setPlayerPrevTokenIndex: Function,
    setOpponentPrevTokenIndex: Function,
    setHumanMovesCompleted: Function,
    setFooterText: Function,
    electricWidth1: number,
    setElectricWidth1: Dispatch<SetStateAction<number>>,
    electricWidth2: number,
    setElectricWidth2: Dispatch<SetStateAction<number>>,
    setAfterComputerMoveFinished?: Function
) => {
    const firstBoom = document.getElementById(`${prevTokenIndex === FIRST_DC_TILE ? 'first-boom-1' : 'second-boom-1'}`) as HTMLDivElement;
    const secondBoom = document.getElementById(`${prevTokenIndex === FIRST_DC_TILE ? 'first-boom-2' : 'second-boom-2'}`) as HTMLDivElement;
    const token = document.getElementById(tokenId) as HTMLImageElement;
    if (firstBoom && secondBoom) {
        AudioHelper.playSound(AudioHelper.ELECTRICITY_ELECTRONICS_Charge);
        setFooterText(GameHelper.playerDCtoACMoveLog(tokenId === 'human-token' ? 0 : 1));
        firstBoom.classList.add('boom-container');
        setTimeout(() => {
            firstBoom.classList.remove('boom-container');
        }, 1000);
        if (prevTokenIndex === FIRST_DC_TILE) token.classList.add('first-dc-to-ac-slide');
        else token.classList.add('second-dc-to-ac-slide');
        setTimeout(() => {
            secondBoom.classList.add('boom-container');
            setTimeout(() => {
                secondBoom.classList.remove('boom-container');
            }, 1000);
            if (prevTokenIndex === FIRST_DC_TILE) {
                setElectricWidth1(400);
            } else {
                setElectricWidth2(400);
            }
        }, DC_TO_AC_MOVEMENT_DURATION / 3);
        setTimeout(() => {
            if (prevTokenIndex === FIRST_DC_TILE) {
                document.getElementById('electric-div-1')?.classList.remove('justify-end');
                document.getElementById('electric-div-1')?.classList.add('justify-start');
                setElectricWidth1(20);
            } else {
                document.getElementById('electric-div-2')?.classList.remove('justify-start');
                document.getElementById('electric-div-2')?.classList.add('justify-end');
                setElectricWidth2(20);
            }
        }, DC_TO_AC_MOVEMENT_DURATION / 3 + 250);
        setTimeout(() => {
            setPlayerPrevTokenIndex(prevTokenIndex === FIRST_DC_TILE ? FIRST_AC_TILE : SECOND_AC_TILE);
            setHumanMovesCompleted(tokenId === 'human-token');
            token.classList.remove('first-dc-to-ac-slide');
            token.classList.remove('second-dc-to-ac-slide');
            if (setAfterComputerMoveFinished) setAfterComputerMoveFinished();
        }, DC_TO_AC_MOVEMENT_DURATION);
        shortCircuitOpponent(G, tokenId, setOpponentPrevTokenIndex, DC_TO_AC_MOVEMENT_DURATION + 1000, setFooterText);
    }
};

const RandomRangeExcept = (max: number, except: number) => {
    do {
        var number = Math.floor(Math.random() * max);
    } while (number === except);
    return number;
};

const useAnimation = ({
    electricWidth2,
    setElectricWidth2,
    electricWidth1,
    setElectricWidth1,
    isLoaded,
    G,
    ctx,
    prevComputerTokenIndex,
    setPrevComputerTokenIndex,
    prevHumanTokenIndex,
    setPrevHumanTokenIndex,
    moves,
    deck,
    setDeck,
    gameStarted,
    setCardSelected,
    isGameOver,
    setIsGameOver,
    setIsAnswerSubmitted,
    footerText,
    setFooterText,
    setShowMove,
}: IUseAnimation) => {
    const [humanMovesCompleted, setHumanMovesCompleted] = useState(false);

    useEffect(() => {
        if (gameStarted && !isGameOver) {
            const element = document.getElementById('top-card');
            if (element && gameStarted) {
                // setFooterText(GameHelper.userTurn)
                if (deck.length > 0 && deck.length !== cardData.length) {
                    element.style.transform = `translate(${0.25 * (deck.length - 1)}px, -${0.25 * (deck.length - 1)}px)`;
                }
                setTimeout(() => {
                    element.style.transition = `1s`;
                }, 100);
                if (humanMovesCompleted) {
                    setHumanMovesCompleted(false);
                    document.getElementById('computer-token')?.classList.add('token-bounce');
                    triggerTokenPositionChange(
                        G,
                        prevComputerTokenIndex,
                        G.computerMiddleTokenPosition,
                        setPrevComputerTokenIndex,
                        setPrevHumanTokenIndex,
                        setHumanMovesCompleted,
                        'computer-token',
                        isGameOver,
                        setIsGameOver,
                        setFooterText,
                        electricWidth1,
                        setElectricWidth1,
                        electricWidth2,
                        setElectricWidth2,
                        () => {
                            setCardSelected(false);
                            document.getElementById('computer-token')!.style.zIndex = '601';
                            setIsAnswerSubmitted(false);
                            document.getElementById('computer-token')?.classList.remove('token-bounce');
                            // document.getElementById('human-token')?.classList.remove('token-bounce');
                        }
                    );
                }
            }
        }
    }, [deck]);

    useEffect(() => {
        // if (G.isHumanSetPosition) {
        //     setPrevHumanTokenIndex(G.humanTokenPosition);
        // } else
        if ((prevHumanTokenIndex !== G.humanTokenPosition || (prevHumanTokenIndex === G.humanTokenPosition && G.humanTokenPosition !== G.humanMiddleTokenPosition)) && !isGameOver) {
            document.getElementById('human-token')?.classList.add('token-bounce');
            triggerTokenPositionChange(G, prevHumanTokenIndex, G.humanMiddleTokenPosition, setPrevHumanTokenIndex, setPrevComputerTokenIndex, setHumanMovesCompleted, 'human-token', isGameOver, setIsGameOver, setFooterText, electricWidth1, setElectricWidth1, electricWidth2, setElectricWidth2);
        } else if (gameStarted && !isGameOver) {
            const humanToken = document.getElementById('human-token');
            if (G.shouldHumanShake) {
                humanToken?.classList.add('shakeX');
                setTimeout(() => {
                    humanToken?.classList.remove('shakeX');
                }, TOKEN_SHAKE_DURATION);
            }
            // setFooterText(GameHelper.userTurn)
            setTimeout(() => {
                document.getElementById('human-token')?.classList.remove('token-bounce');
                setTimeout(() => {
                    setHumanMovesCompleted(true);
                }, 1500);
            }, 500);
        }
    }, [G.humanLastPlayedCard, G.isHumanSetPosition]);

    useEffect(() => {
        // if (G.isComputerSetPosition) {
        //     setPrevComputerTokenIndex(G.computerTokenPosition);
        // } else
        if (humanMovesCompleted && !isGameOver) {
            setTimeout(
                () => {
                    document.getElementById('human-token')?.classList.remove('token-bounce');
                    document.getElementById('computer-token')!.style.zIndex = '603';
                    setFooterText(GameHelper.computersTurn());
                    setTimeout(() => {
                        if (!GameHelper.getPlayerInfo()?.default) {
                            GameHelper.setGameData(G);
                        }
                        const element = document.getElementById('top-card');
                        const computerLastPlayedCard: CardObjectType = G.computerLastPlayedCard!;
                        let answer: number, rand: number;
                        if (computerLastPlayedCard?.Type === 0) {
                            document.getElementById('option-container')?.classList.add('no-hover');
                            setIsAnswerSubmitted(true)
                            answer = computerLastPlayedCard.Questionnaire?.Options.findIndex((opt: OptionsObjectType) => !!opt.IsAnswer)!;
                            if (G.computerLastAnswerCorrect) {
                                rand = answer;
                            } else {
                                rand = RandomRangeExcept(4, answer);
                            }
                        }

                        if (element) {
                            setFooterText(GameHelper.playerPlayedCardLog(1, computerLastPlayedCard.Type));
                            AudioHelper.playSound(AudioHelper.Card_Flip);
                            element.style.transform = 'translate(-400px, -250px) scale(2.75)';
                            element?.classList.add('selected-card');
                            document.getElementById('board-container')?.classList.add('blur-on');
                            if (computerLastPlayedCard?.Type === 0) {
                                setTimeout(() => {
                                    AudioHelper.playSound(AudioHelper.Say_Watt__Answer_Selection);
                                    document.getElementById('option-' + rand)?.classList.add('selected-answer');
                                }, 4000);
                            }
                            setTimeout(
                                () => {
                                    setTimeout(() => {
                                        if (!G.computerLastAnswerCorrect) {
                                            document.getElementById('option-' + rand)?.classList.add('incorrect-answer');
                                            document.getElementById('question-card')?.classList.add('shakeX');
                                            if (G.computerLastPlayedCard?.Type === CardType.SayWatt) {
                                                AudioHelper.playSound(AudioHelper.Incorrect_Answer);
                                            }
                                        } else if (G.computerLastPlayedCard?.Type === CardType.SayWatt) {
                                            AudioHelper.playSound(AudioHelper.Correct_Answer);
                                            document.getElementById('question-card')?.classList.add('heartbeat-without-brightness');
                                            if (document.getElementById('say-watt-text')) {
                                                document.getElementById('say-watt-text')!.style.opacity = '0';
                                            }
                                            setTimeout(() => {
                                                setShowMove(true);
                                            }, 500);
                                            setTimeout(() => {
                                                if (document.getElementById('move-text')) {
                                                    document.getElementById('move-text')!.style.opacity = '1';
                                                }
                                            }, 550);
                                        }
                                        document.getElementById('option-' + rand)?.classList.remove('selected-answer');
                                        if (G.computerLastPlayedCard?.Type === CardType.SayWatt) {
                                            setFooterText(GameHelper.playerAnsweredQuizCardLog(1, G.computerLastAnswerCorrect!));
                                        }
                                        document.getElementById('option-container')?.classList.add('answered');
                                    }, ANSWER_REVEAL_DELAY);
                                    setTimeout(() => {
                                        AudioHelper.playSound(AudioHelper.Card_Disappear);
                                        element.style.transform = 'translate(-400px, 500px) scale(0)';
                                        setIsAnswerSubmitted(false);
                                        document.getElementById('board-container')?.classList.remove('blur-on');
                                        setTimeout(() => {
                                            element?.classList.remove('selected-card');
                                        }, 250);
                                        setTimeout(() => {
                                            element.style.transition = `none`;
                                            setShowMove(false);
                                            if (document.getElementById('say-watt-text')) {
                                                document.getElementById('say-watt-text')!.style.opacity = '1';
                                            }
                                            if (document.getElementById('move-text')) {
                                                document.getElementById('move-text')!.style.opacity = '0';
                                            }
                                            if (!G.computerLastAnswerCorrect) {
                                                document.getElementById('option-' + rand)?.classList.remove('incorrect-answer');
                                                document.getElementById('question-card')?.classList.remove('shakeX');
                                            } else {
                                                document.getElementById('question-card')?.classList.remove('heartbeat-without-brightness');
                                            }
                                            document.getElementById('option-container')?.classList.remove('answered');
                                            document.getElementById('option-container')?.classList.remove('no-hover');
                                            (document.getElementById('card-type-img')! as HTMLImageElement).src = deck[1]?.Type === CardType.Bolt ? '/images/cards/bolt-cards/bolt-card-back.png' : '/images/cards/say-watt-cards/say-watt-card-back.png';
                                        }, 900);
                                        setTimeout(() => {
                                            if ((computerLastPlayedCard?.Type === 0 && G.computerLastAnswerCorrect && G.computerLastPlayedCard?.CardId !== '33') || computerLastPlayedCard?.Type === 1) {
                                                GameHelper.playedCardAnimationOn(computerLastPlayedCard);
                                            }
                                            const tempDeck = deck.filter((x, i) => i !== 0);
                                            if (tempDeck.length > 0 && deck.length !== cardData.length) {
                                                element.style.transform = `translate(${0.25 * (tempDeck.length - 1)}px, -${0.25 * (tempDeck.length - 1)}px)`;
                                            }
                                            setTimeout(() => {
                                                setDeck(tempDeck);
                                                if ((computerLastPlayedCard?.Type === 0 && G.computerLastAnswerCorrect) || computerLastPlayedCard?.Type === 1) {
                                                    setTimeout(() => {
                                                        GameHelper.playedCardAnimationOff(computerLastPlayedCard);
                                                    }, 2000);
                                                }
                                            }, 0);
                                        }, 1000);
                                        setFooterText(GameHelper.playerMoveLog(1, computerLastPlayedCard, G.computerLastAnswerCorrect, prevComputerTokenIndex));
                                    }, 4000);
                                },
                                computerLastPlayedCard?.Type === 0 ? 5000 : 2000
                            );
                        }
                    }, 1000);
                },
                document.getElementById(`mid-renewable-tile`)!.classList.contains('heartBeat') ? 2000 : 0
            );
        }
    }, [G.computerLastPlayedCard, G.isComputerSetPosition, humanMovesCompleted]);

    useEffect(() => {
        if (gameStarted && isLoaded && (prevComputerTokenIndex === STARTING_TILE || prevHumanTokenIndex === STARTING_TILE)) {
            setTimeout(() => {
                GameHelper.displayStartTextVertically();
            }, 1000);
        } else {
            setTimeout(() => {
                GameHelper.displayStartTextHorizontally();
            }, 1000);
        }
    }, [prevComputerTokenIndex, prevHumanTokenIndex]);
};

export default useAnimation;
