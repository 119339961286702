import { ActionType, Card, CardType, CategoryType } from 'models/Card';
import { Questionnaire, QuestionnaireOption } from 'models/Questionnaires';

export const cardData: Card[] = [
    new Card({
        Category: CategoryType.Solar,
        ImageUrl: '/images/cards/bolt-cards/Artboard 2@3x.png',
        Description: 'Stellar! A sunny day to catch some rays! The solar farms harnessed enough electricity to power your whole neighborhood.',
        CardId: '0',
        Type: CardType.Bolt,
        Action: ActionType.UnitMovement,
        MoveValue: 2,
    }),
    new Card({
        Category: CategoryType.Solar,
        ImageUrl: '/images/cards/bolt-cards/Artboard 7.png',
        Description: 'Awesome! A new community solar installment is going up.',
        CardId: '1',
        Type: CardType.Bolt,
        Action: ActionType.SquareMovement,
        MoveValue: null,
    }),
    new Card({
        Category: CategoryType.Solar,
        ImageUrl: '/images/cards/bolt-cards/Artboard 2 copy 2.png',
        Description: 'You installed a battery to store all of your excess solar energy to use at night!',
        CardId: '2',
        Type: CardType.Bolt,
        Action: ActionType.UnitMovement,
        MoveValue: 2,
    }),
    new Card({
        Category: CategoryType.Solar,
        ImageUrl: '/images/cards/bolt-cards/Artboard 2 copy 5_8.png',
        Description: 'Oh no! You mishandled a panel and got shocked!',
        CardId: '5',
        SubText: '(Always have a certified professional work on your panels. Maybe that’s a job for you to consider in the future?!?)',
        Type: CardType.Bolt,
        Action: ActionType.UnitMovement,
        MoveValue: -2,
    }),
    new Card({
        Category: CategoryType.Solar,
        ImageUrl: '/images/cards/bolt-cards/Artboard 2 copy 4.png',
        Description: 'Sweet! Your local state representatives just passed a bill to enable more residential rooftop solar!',
        CardId: '3',
        Type: CardType.Bolt,
        Action: ActionType.UnitMovement,
        MoveValue: 2,
    }),
    new Card({
        Category: CategoryType.Solar,
        ImageUrl: '/images/cards/bolt-cards/Artboard 2 copy 5_2.png',
        Description: 'Darn. A wind storm left gunk all over your panels. You must wait for maintenance to clear it up.',
        CardId: '4',
        SubText: '(Thankfully the wind farm was able to harness all that wind!)',
        Type: CardType.Bolt,
        Action: ActionType.UnitMovement,
        MoveValue: -1,
    }),
    new Card({
        Category: CategoryType.Solar,
        ImageUrl: '/images/cards/bolt-cards/Artboard 2 copy 5_3.png',
        Description: 'It was cloudy and rainy today so no solar energy was generated 😢',
        SubText: '(Hope you have battery storage!)',
        CardId: '6',
        Type: CardType.Bolt,
        Action: ActionType.UnitMovement,
        MoveValue: -1,
    }),
    new Card({
        Category: CategoryType.Solar,
        ImageUrl: '/images/cards/bolt-cards/Artboard 7_1.png',
        Description: 'New electric vehicle chargers were installed at schools & businesses that let us put more midday solar energy to work!',
        CardId: '7',
        Type: CardType.Bolt,
        Action: ActionType.SquareMovement,
        MoveValue: null,
    }),
    new Card({
        Category: CategoryType.Solar,
        ImageUrl: '/images/cards/bolt-cards/Artboard 60_1.png',
        Description: 'Your favorite local ice cream spot just installed solar on their roof. You stopped by to order their new Solar Strawberry flavor.',
        CardId: '8',
        SubText: '(Yummm! 🤤)',
        Type: CardType.Bolt,
        Action: ActionType.UnitMovement,
        MoveValue: 1,
    }),
    new Card({
        Category: CategoryType.Solar,
        ImageUrl: '/images/cards/bolt-cards/Artboard 63.png',
        Description: 'You saved over $1,500 from solar energy on your electric bill this past year. You can afford that electric bicycle now!',
        CardId: '9',
        Type: CardType.Bolt,
        Action: ActionType.UnitMovement,
        MoveValue: 3,
    }),
    new Card({
        Category: CategoryType.Solar,
        ImageUrl: '/images/cards/bolt-cards/Artboard 63_1.png',
        Description: 'Make your voice heard! You and your friends sent in testimony to give better tax breaks for families installing solar...and it PASSED!',
        CardId: '10',
        Type: CardType.Bolt,
        Action: ActionType.UnitMovement,
        MoveValue: 3,
    }),
    new Card({
        Category: CategoryType.Solar,
        ImageUrl: '/images/cards/bolt-cards/Artboard 47_3.png',
        Description: 'One of the cells in your PV array needs a repair. You must purchase a replacement.',
        CardId: '11',
        Type: CardType.Bolt,
        SubText: '(Thankfully you saved $$$ by transitioning to solar in the first place, so you can afford this fix)',
        Action: ActionType.UnitMovement,
        MoveValue: -2,
    }),
    new Card({
        Category: CategoryType.EnergyEfficiency,
        ImageUrl: '/images/cards/bolt-cards/Artboard 2 copy 5_1.png',
        Description: 'We’re getting closer! You helped your state reach 100% clean energy by being more energy-efficient at home & school.',
        CardId: '12',
        Type: CardType.Bolt,
        Action: ActionType.UnitMovement,
        MoveValue: 1,
    }),
    new Card({
        Category: CategoryType.EnergyEfficiency,
        ImageUrl: '/images/cards/bolt-cards/Artboard 2 copy 5_5.png',
        Description: 'Ack! You left the air conditioner on when no one was home. That wasted energy AND money!',
        CardId: '13',
        Type: CardType.Bolt,
        Action: ActionType.UnitMovement,
        MoveValue: -1,
    }),
    new Card({
        Category: CategoryType.SolarAndWind,
        ImageUrl: '/images/cards/bolt-cards/Artboard 2 copy.png',
        Description: 'Great news! Another solar + wind farm is being built! We’re one step closer to reaching 100% renewable energy.',
        CardId: '14',
        Type: CardType.Bolt,
        Action: ActionType.UnitMovement,
        MoveValue: 3,
    }),
    new Card({
        Category: CategoryType.SolarAndWind,
        ImageUrl: '/images/cards/bolt-cards/Artboard 2 copy 3.png',
        Description: 'Your energy company just installed a battery system to store 50 MW of excess solar & wind energy!',
        CardId: '15',
        Type: CardType.Bolt,
        Action: ActionType.UnitMovement,
        MoveValue: 3,
    }),
    new Card({
        Category: CategoryType.SolarAndWind,
        ImageUrl: '/images/cards/bolt-cards/Artboard 2 copy 5.png',
        Description: 'Thanks! You and your friends helped spread awareness about the benefits of the solar + wind farm in your community.',
        CardId: '16',
        Type: CardType.Bolt,
        Action: ActionType.UnitMovement,
        MoveValue: 1,
    }),
    new Card({
        Category: CategoryType.SolarAndWind,
        ImageUrl: '/images/cards/bolt-cards/Artboard 47_1.png',
        Description: 'You decided to join the renewable energy workforce after high school / college!',
        CardId: '17',
        Type: CardType.Bolt,
        Action: ActionType.UnitMovement,
        MoveValue: 2,
    }),
    new Card({
        Category: CategoryType.SolarAndWind,
        ImageUrl: '/images/cards/bolt-cards/Artboard 2 copy 5_4.png',
        Description: 'Noooooo… a blackout! The grid couldn’t handle today’s peak load.',
        CardId: '18',
        SubText: '(Peak load is from 5pm-9pm when everyone is home and using appliances & electronics.)',
        Type: CardType.Bolt,
        Action: ActionType.UnitMovement,
        MoveValue: -3,
    }),
    new Card({
        Category: CategoryType.SolarAndWind,
        ImageUrl: '/images/cards/bolt-cards/Artboard 2 copy 5_6.png',
        Description: 'Blue Planet Foundation educated over 200 students about solar & wind energy at their annual Student Energy Summit.',
        CardId: '19',
        Type: CardType.Bolt,
        Action: ActionType.UnitMovement,
        MoveValue: 1,
    }),
    new Card({
        Category: CategoryType.SolarAndWind,
        ImageUrl: '/images/cards/bolt-cards/Artboard 47_5.png',
        Description: 'Awesome! Another solar + wind farm is being built! This will produce over 350 new local jobs!',
        CardId: '20',
        Type: CardType.Bolt,
        Action: ActionType.UnitMovement,
        MoveValue: 3,
    }),
    new Card({
        Category: CategoryType.Wind,
        ImageUrl: '/images/cards/bolt-cards/Artboard 47_4.png',
        Description: 'We’re a huge fan! It was a windy day and the wind farms harnessed enough electricity to power your whole neighborhood.',
        CardId: '21',
        Type: CardType.Bolt,
        Action: ActionType.UnitMovement,
        MoveValue: 2,
    }),
    new Card({
        Category: CategoryType.Wind,
        ImageUrl: '/images/cards/bolt-cards/Artboard 68_1.png',
        Description: 'Great news! A new wind farm is going up. It will provide over 150,000 kW of clean energy per day!',
        CardId: '22',
        Type: CardType.Bolt,
        Action: ActionType.SquareMovement,
        MoveValue: null,
    }),
    new Card({
        Category: CategoryType.Wind,
        ImageUrl: '/images/cards/bolt-cards/Artboard 2 copy 5_7.png',
        Description: 'Community members raised concerns about the new wind project. The developers need more time to engage with the community.',
        SubText: '(Listening to the community is an important part in achieving an equitable clean energy future.)',
        CardId: '23',
        Type: CardType.Bolt,
        Action: ActionType.UnitMovement,
        MoveValue: -1,
    }),
    new Card({
        Category: CategoryType.Wind,
        ImageUrl: '/images/cards/bolt-cards/Artboard 2 copy 5_8.png',
        Description: 'Oh no! There was a natural disaster that damaged some of the wind turbines.',
        SubText: '(‘Natural’ disasters are actually becoming more frequent and severe due to the increasing amount of CO2 from fossil fuels.)',
        CardId: '24',
        Type: CardType.Bolt,
        Action: ActionType.UnitMovement,
        MoveValue: -2,
    }),
    new Card({
        Category: CategoryType.Wind,
        ImageUrl: '/images/cards/bolt-cards/Artboard 2 copy 5_9.png',
        Description: 'Well, this blows...It wasn’t windy at all today :’(',
        SubText: '(Thankfully it was sunny so there was plenty of clean energy to use!)',
        CardId: '25',
        Type: CardType.Bolt,
        Action: ActionType.UnitMovement,
        MoveValue: -1,
    }),
    new Card({
        Category: CategoryType.Wind,
        ImageUrl: '/images/cards/bolt-cards/Artboard 47_6.png',
        Description: 'Fantastic! With wind energy being cheaper than fossil fuels, your energy bill continues to drop.',
        CardId: '26',
        Type: CardType.Bolt,
        Action: ActionType.UnitMovement,
        MoveValue: 2,
    }),
    new Card({
        Category: CategoryType.Wind,
        ImageUrl: '/images/cards/bolt-cards/Artboard 68_2.png',
        Description: 'Did you know? Wind energy emits zero pollution and has no toxic byproducts, so clean energy can power a strong economy without harming our health or damaging the environment!',
        CardId: '27',
        Type: CardType.Bolt,
        Action: ActionType.UnitMovement,
        MoveValue: 2,
    }),
    new Card({
        Category: CategoryType.Wind,
        ImageUrl: '/images/cards/bolt-cards/Artboard 68_3.png',
        Description: 'A wind-wind situation! Clearway chose their next wind energy site and it does not impact the surrounding landscape and environment.',
        CardId: '28',
        Type: CardType.Bolt,
        Action: ActionType.SquareMovement,
        MoveValue: null,
    }),
    new Card({
        Category: CategoryType.Wind,
        ImageUrl: '/images/cards/bolt-cards/Artboard 68_4.png',
        Description: 'Phew! Clearway conducts multiple studies to mitigate impacts to avian (bird) life in accordance with the U.S. Fish & Wildlife Services.',
        CardId: '29',
        Type: CardType.Bolt,
        Action: ActionType.UnitMovement,
        MoveValue: 1,
    }),
    new Card({
        Category: CategoryType.Wind,
        ImageUrl: '/images/cards/bolt-cards/Artboard 68_5.png',
        Description: '$$$ Multiple studies have found that wind farms have no negative impact on property values, and in some cases, INCREASE the value!',
        CardId: '30',
        Type: CardType.Bolt,
        Action: ActionType.UnitMovement,
        MoveValue: 3,
    }),
    new Card({
        Category: CategoryType.Wind,
        ImageUrl: '/images/cards/bolt-cards/Artboard 68_6.png',
        Description: 'Clearway partnered with Blue Planet Foundation to create a solar + wind energy toolkit for schools across the nation!',
        CardId: '31',
        Type: CardType.Bolt,
        Action: ActionType.UnitMovement,
        MoveValue: 1,
    }),

    // Say Watt cards

    new Card({
        Category: CategoryType.Solar,
        ImageUrl: '/images/cards/bolt-cards/Artboard 1.png',
        Description: '',
        CardId: '31',
        Type: CardType.SayWatt,
        Action: ActionType.UnitMovement,
        MoveValue: 2,
        Questionnaire: new Questionnaire({
            QuestionnaireId: '01',
            Question: '______ : A component of an atom that has a negative electrical charge.',
            Options: [
                new QuestionnaireOption({
                    OptionId: '01',
                    Title: 'Zapper',
                    IsAnswer: false,
                }),
                new QuestionnaireOption({
                    OptionId: '02',
                    Title: 'Electron',
                    IsAnswer: true,
                }),
                new QuestionnaireOption({
                    OptionId: '03',
                    Title: 'Neutron',
                    IsAnswer: false,
                }),
                new QuestionnaireOption({
                    OptionId: '04',
                    Title: 'Proton',
                    IsAnswer: false,
                }),
            ],
        }),
    }),
    new Card({
        Category: CategoryType.Solar,
        ImageUrl: '/images/cards/bolt-cards/Artboard 1.png',
        Description: '',
        CardId: '32',
        Type: CardType.SayWatt,
        Action: ActionType.UnitMovement,
        MoveValue: 2,
        Questionnaire: new Questionnaire({
            QuestionnaireId: '02',
            Question: '_______ : A particle of light from the sun that can produce heat energy.',
            Options: [
                new QuestionnaireOption({
                    OptionId: '01',
                    Title: 'Electron',
                    IsAnswer: false,
                }),
                new QuestionnaireOption({
                    OptionId: '02',
                    Title: 'Lightsaber',
                    IsAnswer: false,
                }),
                new QuestionnaireOption({
                    OptionId: '03',
                    Title: 'Hydrogen',
                    IsAnswer: false,
                }),
                new QuestionnaireOption({
                    OptionId: '04',
                    Title: 'Photon',
                    IsAnswer: true,
                }),
            ],
        }),
    }),
    new Card({
        Category: CategoryType.SolarAndWind,
        ImageUrl: '/images/cards/bolt-cards/Artboard 1.png',
        Description: '',
        CardId: '33',
        Type: CardType.SayWatt,
        Action: ActionType.UnitMovement,
        MoveValue: 1,
        Questionnaire: new Questionnaire({
            QuestionnaireId: '03',
            Question: '_______ : A material that is composed of the remains of organisms preserved in the Earth’s crust with high carbon and hydrogen content.',
            Options: [
                new QuestionnaireOption({
                    OptionId: '01',
                    Title: 'Coal',
                    IsAnswer: false,
                }),
                new QuestionnaireOption({
                    OptionId: '02',
                    Title: 'Oil',
                    IsAnswer: false,
                }),
                new QuestionnaireOption({
                    OptionId: '03',
                    Title: 'Natural Gas',
                    IsAnswer: false,
                }),
                new QuestionnaireOption({
                    OptionId: '04',
                    Title: 'All of the above, fossil fuels',
                    IsAnswer: true,
                }),
            ],
        }),
    }),
    new Card({
        Category: CategoryType.SolarAndWind,
        ImageUrl: '/images/cards/bolt-cards/Artboard 1.png',
        Description: '',
        CardId: '34',
        Type: CardType.SayWatt,
        Action: ActionType.UnitMovement,
        MoveValue: 1,
        Questionnaire: new Questionnaire({
            QuestionnaireId: '04',
            Question: '_______ : A star in our galaxy that makes life on Earth possible.',
            Options: [
                new QuestionnaireOption({
                    OptionId: '01',
                    Title: 'iCloud',
                    IsAnswer: false,
                }),
                new QuestionnaireOption({
                    OptionId: '02',
                    Title: 'Sun',
                    IsAnswer: true,
                }),
                new QuestionnaireOption({
                    OptionId: '03',
                    Title: 'Sirius',
                    IsAnswer: false,
                }),
                new QuestionnaireOption({
                    OptionId: '04',
                    Title: 'Nova',
                    IsAnswer: false,
                }),
            ],
        }),
    }),
    new Card({
        Category: CategoryType.Solar,
        ImageUrl: '/images/cards/bolt-cards/Artboard 1.png',
        Description: '',
        CardId: '35',
        Type: CardType.SayWatt,
        Action: ActionType.UnitMovement,
        MoveValue: 1,
        Questionnaire: new Questionnaire({
            QuestionnaireId: '05',
            Question: '_______ : Energy resulting from the flow of electrons.',
            Options: [
                new QuestionnaireOption({
                    OptionId: '01',
                    Title: 'Nuclear',
                    IsAnswer: false,
                }),
                new QuestionnaireOption({
                    OptionId: '02',
                    Title: 'Electricity',
                    IsAnswer: true,
                }),
                new QuestionnaireOption({
                    OptionId: '03',
                    Title: 'Wind',
                    IsAnswer: false,
                }),
                new QuestionnaireOption({
                    OptionId: '04',
                    Title: 'Wi-Fi',
                    IsAnswer: false,
                }),
            ],
        }),
    }),
    new Card({
        Category: CategoryType.Solar,
        ImageUrl: '/images/cards/bolt-cards/Artboard 1.png',
        Description: '',
        CardId: '36',
        Type: CardType.SayWatt,
        Action: ActionType.UnitMovement,
        MoveValue: 1,
        Questionnaire: new Questionnaire({
            QuestionnaireId: '06',
            Question: '_______ : A cell that converts light (photons) into electricity.',
            Options: [
                new QuestionnaireOption({
                    OptionId: '01',
                    Title: 'Solar Panel',
                    IsAnswer: true,
                }),
                new QuestionnaireOption({
                    OptionId: '02',
                    Title: 'Solar Proton',
                    IsAnswer: false,
                }),
                new QuestionnaireOption({
                    OptionId: '03',
                    Title: 'Solar Prototype',
                    IsAnswer: false,
                }),
                new QuestionnaireOption({
                    OptionId: '04',
                    Title: 'Solar Prius',
                    IsAnswer: false,
                }),
            ],
        }),
    }),
    new Card({
        Category: CategoryType.SolarAndWind,
        ImageUrl: '/images/cards/bolt-cards/Artboard 1.png',
        Description: '',
        CardId: '37',
        Type: CardType.SayWatt,
        Action: ActionType.UnitMovement,
        MoveValue: 3,
        Questionnaire: new Questionnaire({
            QuestionnaireId: '07',
            Question: '_______ : The form of electrical current that is used to deliver power to houses and businesses.',
            Options: [
                new QuestionnaireOption({
                    OptionId: '01',
                    Title: 'Alternating Current',
                    IsAnswer: true,
                }),
                new QuestionnaireOption({
                    OptionId: '02',
                    Title: 'Direct Current',
                    IsAnswer: false,
                }),
                new QuestionnaireOption({
                    OptionId: '03',
                    Title: 'Dual Current',
                    IsAnswer: false,
                }),
                new QuestionnaireOption({
                    OptionId: '04',
                    Title: 'Strong Current',
                    IsAnswer: false,
                }),
            ],
        }),
    }),
    new Card({
        Category: CategoryType.SolarAndWind,
        ImageUrl: '/images/cards/bolt-cards/Artboard 1.png',
        Description: '',
        CardId: '38',
        Type: CardType.SayWatt,
        Action: ActionType.UnitMovement,
        MoveValue: 1,
        Questionnaire: new Questionnaire({
            QuestionnaireId: '08',
            Question: '_______ : A device that measures the amount of electric energy used by a consumer.',
            Options: [
                new QuestionnaireOption({
                    OptionId: '01',
                    Title: 'Volt Meter',
                    IsAnswer: false,
                }),
                new QuestionnaireOption({
                    OptionId: '02',
                    Title: 'Current Counter',
                    IsAnswer: false,
                }),
                new QuestionnaireOption({
                    OptionId: '03',
                    Title: 'Electric Meter',
                    IsAnswer: true,
                }),
                new QuestionnaireOption({
                    OptionId: '04',
                    Title: 'Parking Meter',
                    IsAnswer: false,
                }),
            ],
        }),
    }),
    new Card({
        Category: CategoryType.SolarAndWind,
        ImageUrl: '/images/cards/bolt-cards/Artboard 1.png',
        Description: '',
        CardId: '39',
        Type: CardType.SayWatt,
        Action: ActionType.UnitMovement,
        MoveValue: 2,
        Questionnaire: new Questionnaire({
            QuestionnaireId: '09',
            Question: '_______ : The maximum amount of power demand on the electrical grid.',
            Options: [
                new QuestionnaireOption({
                    OptionId: '01',
                    Title: 'Super Load',
                    IsAnswer: false,
                }),
                new QuestionnaireOption({
                    OptionId: '02',
                    Title: 'Peak Load',
                    IsAnswer: true,
                }),
                new QuestionnaireOption({
                    OptionId: '03',
                    Title: 'Charge Load',
                    IsAnswer: false,
                }),
                new QuestionnaireOption({
                    OptionId: '04',
                    Title: 'Mother Load',
                    IsAnswer: false,
                }),
            ],
        }),
    }),
    new Card({
        Category: CategoryType.Solar,
        ImageUrl: '/images/cards/bolt-cards/Artboard 1.png',
        Description: '',
        CardId: '40',
        Type: CardType.SayWatt,
        Action: ActionType.UnitMovement,
        MoveValue: 2,
        Questionnaire: new Questionnaire({
            QuestionnaireId: '10',
            Question: '_______ : The process of converting light into electricity.',
            Options: [
                new QuestionnaireOption({
                    OptionId: '01',
                    Title: 'Voltage',
                    IsAnswer: false,
                }),
                new QuestionnaireOption({
                    OptionId: '02',
                    Title: 'Wattage',
                    IsAnswer: false,
                }),
                new QuestionnaireOption({
                    OptionId: '03',
                    Title: 'Photovoltaic',
                    IsAnswer: true,
                }),
                new QuestionnaireOption({
                    OptionId: '04',
                    Title: 'Photobomb',
                    IsAnswer: false,
                }),
            ],
        }),
    }),
    new Card({
        Category: CategoryType.SolarAndWind,
        ImageUrl: '/images/cards/bolt-cards/Artboard 1.png',
        Description: '',
        CardId: '41',
        Type: CardType.SayWatt,
        Action: ActionType.UnitMovement,
        MoveValue: 3,
        Questionnaire: new Questionnaire({
            QuestionnaireId: '01',
            Question: '_______ : The amount of electromotive force that exists between two points.',
            Options: [
                new QuestionnaireOption({
                    OptionId: '11',
                    Title: 'Law of Attraction',
                    IsAnswer: false,
                }),
                new QuestionnaireOption({
                    OptionId: '02',
                    Title: 'Magnetism',
                    IsAnswer: false,
                }),
                new QuestionnaireOption({
                    OptionId: '03',
                    Title: 'Wattage',
                    IsAnswer: false,
                }),
                new QuestionnaireOption({
                    OptionId: '04',
                    Title: 'Voltage',
                    IsAnswer: true,
                }),
            ],
        }),
    }),
    new Card({
        Category: CategoryType.SolarAndWind,
        ImageUrl: '/images/cards/bolt-cards/Artboard 1.png',
        Description: '',
        CardId: '42',
        Type: CardType.SayWatt,
        Action: ActionType.UnitMovement,
        MoveValue: 1,
        Questionnaire: new Questionnaire({
            QuestionnaireId: '12',
            Question: '_______ : Can store electrical energy in times of low or no renewable energy production.',
            Options: [
                new QuestionnaireOption({
                    OptionId: '01',
                    Title: 'Thor',
                    IsAnswer: false,
                }),
                new QuestionnaireOption({
                    OptionId: '02',
                    Title: 'Transformer',
                    IsAnswer: false,
                }),
                new QuestionnaireOption({
                    OptionId: '03',
                    Title: 'Battery',
                    IsAnswer: true,
                }),
                new QuestionnaireOption({
                    OptionId: '04',
                    Title: 'Substation',
                    IsAnswer: false,
                }),
            ],
        }),
    }),
    new Card({
        Category: CategoryType.SolarAndWind,
        ImageUrl: '/images/cards/bolt-cards/Artboard 1.png',
        Description: '',
        CardId: '43',
        Type: CardType.SayWatt,
        Action: ActionType.UnitMovement,
        MoveValue: 3,
        Questionnaire: new Questionnaire({
            QuestionnaireId: '13',
            Question: '_______ : A type of electrical current that flows in one uniform direction. Used in fast chargers for electric vehicles.',
            Options: [
                new QuestionnaireOption({
                    OptionId: '01',
                    Title: 'Alternating Current',
                    IsAnswer: false,
                }),
                new QuestionnaireOption({
                    OptionId: '02',
                    Title: 'Direct Current',
                    IsAnswer: true,
                }),
                new QuestionnaireOption({
                    OptionId: '03',
                    Title: 'Dual Current',
                    IsAnswer: false,
                }),
                new QuestionnaireOption({
                    OptionId: '04',
                    Title: 'Actual Current',
                    IsAnswer: false,
                }),
            ],
        }),
    }),
    new Card({
        Category: CategoryType.SolarAndWind,
        ImageUrl: '/images/cards/bolt-cards/Artboard 1.png',
        Description: '',
        CardId: '44',
        Type: CardType.SayWatt,
        Action: ActionType.UnitMovement,
        MoveValue: 2,
        Questionnaire: new Questionnaire({
            QuestionnaireId: '14',
            Question: '_______ : A device that converts DC electricity into AC electricity.',
            Options: [
                new QuestionnaireOption({
                    OptionId: '01',
                    Title: 'Transformer',
                    IsAnswer: false,
                }),
                new QuestionnaireOption({
                    OptionId: '02',
                    Title: 'Neutron',
                    IsAnswer: false,
                }),
                new QuestionnaireOption({
                    OptionId: '03',
                    Title: 'Xbox',
                    IsAnswer: false,
                }),
                new QuestionnaireOption({
                    OptionId: '04',
                    Title: 'Inverter',
                    IsAnswer: true,
                }),
            ],
        }),
    }),
    new Card({
        Category: CategoryType.SolarAndWind,
        ImageUrl: '/images/cards/bolt-cards/Artboard 1.png',
        Description: '',
        CardId: '45',
        Type: CardType.SayWatt,
        Action: ActionType.UnitMovement,
        MoveValue: 2,
        Questionnaire: new Questionnaire({
            QuestionnaireId: '15',
            Question: '_______ : A standard unit of electrical power used to measure electricity consumption.',
            Options: [
                new QuestionnaireOption({
                    OptionId: '01',
                    Title: 'Amp',
                    IsAnswer: false,
                }),
                new QuestionnaireOption({
                    OptionId: '02',
                    Title: 'Voltage',
                    IsAnswer: false,
                }),
                new QuestionnaireOption({
                    OptionId: '03',
                    Title: 'Killowatt (kW)',
                    IsAnswer: true,
                }),
                new QuestionnaireOption({
                    OptionId: '04',
                    Title: 'Solar Snaps',
                    IsAnswer: false,
                }),
            ],
        }),
    }),
    new Card({
        Category: CategoryType.Wind,
        ImageUrl: '/images/cards/bolt-cards/Artboard 1.png',
        Description: '',
        CardId: '46',
        Type: CardType.SayWatt,
        Action: ActionType.UnitMovement,
        MoveValue: 1,
        Questionnaire: new Questionnaire({
            QuestionnaireId: '46',
            Question: '_______ : How is wind created?',
            Options: [
                new QuestionnaireOption({
                    OptionId: '01',
                    Title: 'Birds flying too fast',
                    IsAnswer: false,
                }),
                new QuestionnaireOption({
                    OptionId: '02',
                    Title: 'Waves crashing in the ocean',
                    IsAnswer: false,
                }),
                new QuestionnaireOption({
                    OptionId: '03',
                    Title: 'Everyone exhaling all at once',
                    IsAnswer: false,
                }),
                new QuestionnaireOption({
                    OptionId: '04',
                    Title: 'Uneven heating of the earth’s surface',
                    IsAnswer: true,
                }),
            ],
        }),
    }),
    new Card({
        Category: CategoryType.Wind,
        ImageUrl: '/images/cards/bolt-cards/Artboard 1.png',
        Description: '',
        CardId: '47',
        Type: CardType.SayWatt,
        Action: ActionType.UnitMovement,
        MoveValue: 2,
        Questionnaire: new Questionnaire({
            QuestionnaireId: '17',
            Question: '_______ : A device (box) that attaches the three propeller-like blades at the top of a wind turbine',
            Options: [
                new QuestionnaireOption({
                    OptionId: '01',
                    Title: 'Nacelle',
                    IsAnswer: true,
                }),
                new QuestionnaireOption({
                    OptionId: '02',
                    Title: 'Neutron',
                    IsAnswer: false,
                }),
                new QuestionnaireOption({
                    OptionId: '03',
                    Title: 'Needle',
                    IsAnswer: false,
                }),
                new QuestionnaireOption({
                    OptionId: '04',
                    Title: 'Ninja',
                    IsAnswer: false,
                }),
            ],
        }),
    }),
    new Card({
        Category: CategoryType.Wind,
        ImageUrl: '/images/cards/bolt-cards/Artboard 1.png',
        Description: '',
        CardId: '48',
        Type: CardType.SayWatt,
        Action: ActionType.UnitMovement,
        MoveValue: 3,
        Questionnaire: new Questionnaire({
            QuestionnaireId: '18',
            Question: '_______ : A device that increases the electrical output before sending it to the electrical grid.',
            Options: [
                new QuestionnaireOption({
                    OptionId: '01',
                    Title: 'Transformer',
                    IsAnswer: true,
                }),
                new QuestionnaireOption({
                    OptionId: '02',
                    Title: 'Neutron',
                    IsAnswer: false,
                }),
                new QuestionnaireOption({
                    OptionId: '03',
                    Title: 'Xbox',
                    IsAnswer: false,
                }),
                new QuestionnaireOption({
                    OptionId: '04',
                    Title: 'Inverter ',
                    IsAnswer: false,
                }),
            ],
        }),
    }),
    new Card({
        Category: CategoryType.Wind,
        ImageUrl: '/images/cards/bolt-cards/Artboard 1.png',
        Description: '',
        CardId: '49',
        Type: CardType.SayWatt,
        Action: ActionType.UnitMovement,
        MoveValue: 1,
        Questionnaire: new Questionnaire({
            QuestionnaireId: '19',
            Question: '_______ : What causes the least amount of bird deaths per year?',
            Options: [
                new QuestionnaireOption({
                    OptionId: '01',
                    Title: 'Building windows (collisions)',
                    IsAnswer: false,
                }),
                new QuestionnaireOption({
                    OptionId: '02',
                    Title: 'Wind turbines',
                    IsAnswer: true,
                }),
                new QuestionnaireOption({
                    OptionId: '03',
                    Title: 'Feral cats',
                    IsAnswer: false,
                }),
                new QuestionnaireOption({
                    OptionId: '04',
                    Title: 'Communication towers',
                    IsAnswer: false,
                }),
            ],
        }),
    }),
    new Card({
        Category: CategoryType.Wind,
        ImageUrl: '/images/cards/bolt-cards/Artboard 1.png',
        Description: '',
        CardId: '50',
        Type: CardType.SayWatt,
        Action: ActionType.UnitMovement,
        MoveValue: 2,
        Questionnaire: new Questionnaire({
            QuestionnaireId: '20',
            Question: '_______ : What is the fastest growing occupation in the United States? ',
            Options: [
                new QuestionnaireOption({
                    OptionId: '01',
                    Title: 'Oil & gas drill operator',
                    IsAnswer: false,
                }),
                new QuestionnaireOption({
                    OptionId: '02',
                    Title: 'Solar panel installer',
                    IsAnswer: false,
                }),
                new QuestionnaireOption({
                    OptionId: '03',
                    Title: 'Wind turbine technician',
                    IsAnswer: true,
                }),
                new QuestionnaireOption({
                    OptionId: '04',
                    Title: 'Magician',
                    IsAnswer: false,
                }),
            ],
        }),
    }),
];
