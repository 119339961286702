import 'components/electric-sparks/Electric.css';

import { useRef, useEffect, Dispatch, SetStateAction, CSSProperties, useState } from 'react';

interface IElectric {
    electricWidth: number;
    setElectricWidth: Dispatch<SetStateAction<number>>;
    id: string;
    className: string;
    style: CSSProperties;
}

const Electric = ({ electricWidth, setElectricWidth, ...rest }: IElectric) => {
    let opts = {
        lineWidth: 4,
        numberOfPoints: 20,
        amplitude: 10,
        spacing: 10,
        margin: 10,
        fixedWidth: 20,
    };

    const [showElectric, setShowElectric] = useState(false);

    const svg = useRef<SVGSVGElement>(null);

    // Set up SVG box
    // let svg = document.querySelector('svg');

    function runAnimation(fixedWidth: number) {
        if (svg.current) {
            let width = fixedWidth || (opts.numberOfPoints - 1) * opts.spacing + opts.margin * 2;
            let height = opts.amplitude + opts.margin * 2;

            svg.current.style.width = width + 'px';
            svg.current.style.height = height + 'px';

            // Generate coordinates
            let points: number[] = [];
            for (let i = opts.numberOfPoints; i--; ) points.push(i);

            let animate = () => {
                let coords = points.map((n) => {
                    let first = n == 0;
                    let last = n == opts.numberOfPoints - 1;
                    let x = ((width - opts.margin * 2) / (opts.numberOfPoints - 1)) * n + opts.margin;
                    let y = first || last ? height / 2 : (height - opts.amplitude) / 2 + Math.random() * opts.amplitude;

                    return { x, y };
                });

                // Draw path
                let path = svg.current!.querySelector('path');
                path!.setAttribute('d', 'M' + coords.map((coord) => coord.x + ',' + coord.y).join(' L'));

                // Style path
                let deviation = Math.random() * (5 - 2) + 2;
                path!.style.opacity = (deviation / 5 + 0.2).toString();
                path!.style.strokeWidth = opts.lineWidth.toString();

                // Style glow
                let glow = svg.current!.querySelector('#glow feDropShadow');
                glow!.setAttribute('stdDeviation', deviation.toString());

                // Loop
                const a = requestAnimationFrame(animate);
                if (fixedWidth <= 19) {
                    cancelAnimationFrame(a);
                }
            };
            // Initiate animation
            const b = requestAnimationFrame(animate);
            if (fixedWidth <= 19) {
                cancelAnimationFrame(b);
            }
        }
    }

    useEffect(() => {
        // setShowElectric(true);
        if (electricWidth >= 100 && svg.current) {
            for (let i = 20; i < electricWidth + 1; i++) {
                setTimeout(() => {
                    runAnimation(i);
                    // if(i === 400) setShowElectric(false)
                }, (i - 19) * 0.5);
            }
        } else {
            for (let i = 400; i > 19; i--) {
                setTimeout(() => {
                    runAnimation(i);
                    // if(i === 20) setShowElectric(false);
                }, (401 - i) * 0.5);
            }
        }
    }, [electricWidth]);

    return (
        <div {...rest}>
            {showElectric && (
                <svg className="" ref={svg}>
                    <defs>
                        <filter id="glow" x="-100%" y="-100%" width="300%" height="300%">
                            <feDropShadow dx="0" dy="0" stdDeviation="3"></feDropShadow>
                        </filter>
                    </defs>
                    <path style={{ filter: 'url(#glow)' }} d="M10,0 L100,0" />
                </svg>
            )}
        </div>
    );
};

export default Electric;
