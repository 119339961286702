import 'components/loading-bar/LoadingBar.css';

export default function LoadingBar() {
    return <div id="loading-bar-container" className="w-full h-full absolute top-0" style={{zIndex: 1000, backgroundColor: 'rgb(255,255,255,0.5)', backdropFilter: 'blur(8px)'}}>
        <div className="flex flex-col justify-center items-center h-full">                        
            <div className="font-bold loading-text" style={{fontStyle: 'italic', fontFamily: 'Greycliff CF', fontSize: '150%'}}>Loading</div>
            <div className="w-1/4 h-6 flex border border-dark-greenish-blue rounded-xl overflow-hidden p-0.5">
                <div id="load-bar" className="rounded-xl h-full bg-dark-greenish-blue load-bar" style={{ width: '0%'}}></div>
            </div>                    
        </div>
    </div>
}