class GameAudio extends Audio {
    public constructor(init?: any) {
        super();
        this.preload = 'auto';
        this.controls = true;
        this.muted = true;
        this.setAttribute('controlsList', 'nodownload');
        this.src = init;
        console.log(this);
    }
}

export class AudioHelper {
    static Pep_molina_woohoo = new GameAudio('/audios/SolarSlide/220691__pep-molina__woohoo.mp3');
    static Yipee_Kid = new GameAudio('/audios/SolarSlide/Yipee_Kid.wav');
    static Welcome_Message_Appear = new GameAudio('/audios/1___2._Welcome_Message_Appear.mp3');
    static Welcome_Message_Gone = new GameAudio('/audios/1___2._Welcome_Message_Gone.mp3');
    static Shuffling_Cards = new GameAudio('/audios/3. shuffling-cards-2.mp3');
    static Start_Game_Countdown = new GameAudio('/audios/4. Start_Game_Countdown.wav');
    static Card_Flip = new GameAudio('/audios/(4)_Card_Flip_2nd_sound.mp3');
    static Card_Disappear = new GameAudio('/audios/(5)_Card_Transfer_Deck_to_Across_Screen_slow_down.mp3');
    static WHOOSH = new GameAudio('/audios/(16)_WHOOSH.mp3');
    static Say_Watt__Answer_Selection = new GameAudio('/audios/(3)_Card_and_Answer_Selection.mp3');
    static Correct_Answer = new GameAudio('/audios/9. Correct_Answer (1st sound).mp3');
    static Incorrect_Answer = new GameAudio('/audios/10. Incorrect_Answer (1st sound).mp3');
    static WIND_Howl_And_Whistle = new GameAudio('/audios/11._WIND_Howl_And_Whistle.mp3');
    static Peak_Load_Panic = new GameAudio('/audios/12. Peak_Load_Panic.mp3');
    static awkward_tag = new GameAudio('/audios/13. awkward-tag.flac');
    static xylophone_eye_blinks = new GameAudio('/audios/14. xylophone-eye-blinks.wav');
    static ELECTRICITY_ELECTRONICS_Charge = new GameAudio('/audios/16. ELECTRICITY_ELECTRONICS_Charge (Sound 2).mp3');
    static Game_Lost_Sad_Trombone = new GameAudio('/audios/17. Game_Lost_Sad_Trombone.mp3');
    static Game_Won_Crowd_Cheering = new GameAudio('/audios/18. Game_Won_Crowd_Cheering.wav');
    static yipee = new GameAudio('/audios/SolarSlide/Yipeeeee.mp3');
    static game_music = new GameAudio('/audios/19. Avocado_Junkie_Kidomatic_instrumental.mp3');

    public static playSound = (sound: GameAudio) => {
        if (sound === this.game_music) {
            sound.loop = true;
            sound.volume = 0.2;
        }
        if (sound === this.Game_Won_Crowd_Cheering) {
            sound.loop = true;
        } else if(sound === this.Card_Flip) {
            sound.volume = 0.3;
        }
        sound.muted = false;
        sound.play();
        console.log(sound);
    };

    public static toggleSound = (sound: GameAudio) => {
        sound.muted = !sound.muted;
    };

    public static playAll = () => {
        AudioHelper.Pep_molina_woohoo.play();
        AudioHelper.Yipee_Kid.play();
        AudioHelper.Welcome_Message_Appear.play();
        AudioHelper.Welcome_Message_Gone.play();
        AudioHelper.Shuffling_Cards.play();
        AudioHelper.Start_Game_Countdown.play();
        AudioHelper.Card_Flip.play();
        AudioHelper.Card_Disappear.play();
        AudioHelper.WHOOSH.play();
        AudioHelper.Say_Watt__Answer_Selection.play();
        AudioHelper.Correct_Answer.play();
        AudioHelper.Incorrect_Answer.play();
        AudioHelper.WIND_Howl_And_Whistle.play();
        AudioHelper.Peak_Load_Panic.play();
        AudioHelper.awkward_tag.play();
        AudioHelper.xylophone_eye_blinks.play();
        AudioHelper.ELECTRICITY_ELECTRONICS_Charge.play();
        AudioHelper.Game_Lost_Sad_Trombone.play();
        AudioHelper.Game_Won_Crowd_Cheering.play();
        AudioHelper.yipee.play();
        AudioHelper.game_music.play();
    };
}
