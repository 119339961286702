import 'components/winner-message/WinnerMessage.css';
import { AudioHelper } from 'helpers/AudioHelper';
import { GameHelper } from 'helpers/GameHelper';
import { useEffect } from 'react';
import Confetti from '../confetti/Confetti';

function WinnerMessage(props: any) {
    useEffect(() => {
        if (props.appear) {
            AudioHelper.playSound(props.won ? AudioHelper.Game_Won_Crowd_Cheering : AudioHelper.Game_Lost_Sad_Trombone);
            if (props.won) {
                for (let i = 0; i < 3; i++) {
                    setTimeout(() => {
                        AudioHelper.playSound(AudioHelper.yipee);
                    }, (i + 1) * 4000);
                }
            }
            document.getElementById('wind')!.style.opacity = '1';
            if (props.won) {
                document.getElementById('last-renewable-tile')!.style.transform = 'scale(1.4)';
                document.getElementById('last-renewable-tile')!.style.zIndex = '1000';
            }
            props.setFooterText(GameHelper.setWinnerText(props.won));
            GameHelper.updatePlayerInfo({ replay: true });
            // if (props.won) {
            //     AudioHelper.playSound('Game_Won_Crowd_Cheering_0_6_sec');
            // } else {
            //     AudioHelper.playSound('Game_Lost_Sad_Trombone');
            // }
        }
    }, [props.appear]);

    const restartGame = () => {
        window.location.reload();
    };

    const redirectToHome = () => {
        window.parent.location.href = 'https://cleanenergyconnect.org';
    };

    return (
        <>
            <div id="winner-message" className={`winner-message${props.won ? '' : '_lost'} ${props.appear ? 'appear' : ''}`}>
                <div className={`winner-message__colored-blocks${props.won ? '' : '_lost'} block-translate`} style={{ zIndex: 2000000 }}>
                    {props.won && (
                        <div className="winner-message__colored-blocks-rotater">
                            <div className="winner-message__colored-block"></div>
                            <div className="winner-message__colored-block"></div>
                            <div className="winner-message__colored-block"></div>
                        </div>
                    )}
                    <div className="winner-message__colored-blocks-inner"></div>
                    <div className={`winner-message__text${props.won ? '' : '_lost'}`}> {props.won ? 'You Won' : 'Better Luck Next Time'}</div>
                </div>
                <div className="flex w-full box-translate" style={props.won ? { fontStyle: 'italic', fontFamily: 'Greycliff CF', zIndex: 1999900 } : { position: 'absolute', margin: '0 -10px', fontStyle: 'italic', fontFamily: 'Greycliff CF', zIndex: 1999900, maxWidth: '475px', left: '23%' }}>
                    <div className="bg-white flex-grow rounded-lg mx-10 flex justify-around items-center text-lg" style={{ height: '110px', paddingTop: '25px', zIndex: 2000, pointerEvents: props.appear ? 'all' : 'none' }}>
                        <div className="bg-pattern" style={{ backgroundImage: `url('/images/Pattern.png')`, backgroundSize: '1150px', backgroundPosition: 'center' }}></div>
                        <div className="hover-color cursor-pointer duration-100" onClick={restartGame}>
                            Play Again
                        </div>
                        <div className="duration-100 cursor-pointer hover-color" onClick={redirectToHome}>
                            Return to home page
                        </div>
                        {/* <a href="https://cleanenergyconnect.org" rel="noreferrer" className="duration-100 cursor-pointer hover-color">Return to home page</a> */}
                    </div>
                </div>
            </div>

            {props.won && props.appear && <Confetti />}
        </>
    );
}

export default WinnerMessage;
