import 'components/board-layout/BoardLayout.css';
import BouncingArrow from 'components/bouncing-arrow/BouncingArrow';
import Windmill from 'components/windmill/Windmill';
import { GameHelper } from 'helpers/GameHelper';

export default function BoardLayout({ children }: any) {
    return (
        <div id="board-layout" className="set-min-max-width m-auto w-full h-full duration-1000 pointer-events-none">
            <div
                className="relative overflow-hidden m-auto w-full board-div blur-8"
                style={{
                    background: `url(/images/Artboard_1.png)`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'contain',
                    // backgroundColor: '#39b54a',
                    maxWidth: '1200px',
                    minWidth: '1200px',
                    minHeight: '776.493px',
                    maxHeight: '776.493px',
                    zIndex: 100,
                }}
            >
                <div
                    className="relative overflow-hidden m-auto w-full static-assets-container blur-8"
                    style={{
                        maxWidth: '1200px',
                        minWidth: '1200px',
                        minHeight: '776.493px',
                        maxHeight: '776.493px',
                    }}
                >
                    {/* clouds */}
                    <img src="/images/cloud1.png" alt="cloud" style={{ width: '190px', top: '72px', right: '-35px', animation: `cloud-animation ${GameHelper.randomGenerator(75, 80)}s infinite ease-in-out`, zIndex: 400 }} />
                    <img src="/images/cloud2.png" alt="cloud" style={{ width: '144px', top: '278px', right: '150px', animation: `cloud-animation ${GameHelper.randomGenerator(75, 80)}s infinite ease-in-out`, zIndex: 400 }} />
                    <img src="/images/cloud3.png" alt="cloud" style={{ width: '151px', top: '60px', right: '370px', animation: `cloud-animation ${GameHelper.randomGenerator(75, 80)}s infinite ease-in-out`, zIndex: 400 }} />

                    <img src="/images/cat.png" alt="character" style={{ width: '29px', top: '537px', left: '234px', zIndex: 1002 }} />
                    <img src="/images/tail.png" alt="character" style={{ width: '12px', top: '538px', left: '252px', zIndex: 1001, transformOrigin: 'left', animation: `tail-movement 5s infinite ease-in-out` }} />
                    <img src="/images/Artboard 27.png" alt="character" style={{ width: '400px', bottom: '36px', left: '360px', zIndex: 502 }} />

                    <img src="/images/wave1.png" alt="waves" style={{ minWidth: '1200px', bottom: '0px', right: '0px', zIndex: 501, animation: `deep-water-animation 5s infinite ease-in` }} />
                    <img src="/images/wave2.png" alt="waves" style={{ minWidth: '1210px', minHeight: '787px', bottom: '0px', right: '0px', zIndex: 500, animation: `wave-water-animation 8s infinite ease-in` }} />

                    <img src="/images/forest_323x.png" alt="character" style={{ width: '235px', top: '115px', left: '70px', zIndex: 500 }} />
                    <img src="/images/solar_panels_on_hill_1_313x.png" alt="character" style={{ width: '155px', top: '155px', left: '320px', zIndex: 500 }} />
                    <img src="/images/ice_mountains_423x.png" alt="character" style={{ width: '316px', top: '350px', left: '165px', zIndex: 500 }} />
                    <img src="/images/barn_363x.png" alt="character" style={{ width: '293px', top: '475px', left: '145px', zIndex: 500 }} />
                    <img src="/images/city_with_mountain_193x.png" alt="character" style={{ width: '415px', top: '280px', left: '650px', zIndex: 500 }} />
                    <img src="/images/solar_panels_on_hill_2_313x.png" alt="character" style={{ width: '121px', top: '335px', left: '1080px', zIndex: 500 }} />
                    <img src="/images/city_353x.png" alt="character" style={{ width: '143.5px', top: '430px', left: '795px', zIndex: 500 }} />
                    <img src="/images/mountain_283x.png" alt="character" style={{ width: '278px', top: '530px', left: '605px', zIndex: 500 }} />
                    <img src="/images/grass_383x.png" alt="character" style={{ width: '48px', top: '385px', left: '550px', zIndex: 500 }} />

                    <img src="/images/solar_panel_413x.png" alt="character" id="solar-panel-1" style={{ width: '69px', top: '45px', left: '590px', zIndex: 600 }} />
                    <img src="/images/sparkle.png" className="sparkle" alt="character" style={{ width: '69px', top: '12px', left: '570px', zIndex: 601, transform: 'scale(0)' }} />
                    <img src="/images/sparkle.png" className="sparkle" alt="character" style={{ width: '69px', top: '60px', left: '607px', zIndex: 601, transform: 'scale(0)', animationDelay: '0.5s' }} />

                    <img src="/images/solar_panel_413x_flip.png" alt="character" id="solar-panel-2" style={{ width: '69px', top: '270px', left: '380px', zIndex: 600 }} />
                    <img src="/images/sparkle.png" className="sparkle" alt="character" style={{ width: '69px', top: '237px', left: '400px', zIndex: 601, transform: 'scale(0)' }} />
                    <img src="/images/sparkle.png" className="sparkle" alt="character" style={{ width: '69px', top: '285px', left: '360px', zIndex: 601, transform: 'scale(0)', animationDelay: '0.5s' }} />

                    <img src="/images/peak_load_panic_403x.png" alt="character" style={{ width: '121px', top: '382px', left: '755px', zIndex: 300 }} />

                    <img src="/images/sun_141x.png" alt="sun" id="sun" style={{ width: '345px', top: '-55px', left: '820px', zIndex: 200 }} />
                    {/* <img src="/images/Artboard 46.png" alt="text" style={{ width: '375px', top: '109px', right: '64px', zIndex: 400 }} /> */}
                    <img src="/images/road_to.png" alt="text" style={{ width: '84px', top: '109px', right: '350px', zIndex: 400 }} />
                    <img src="/images/renewable.png" id="renewable-img" alt="text" style={{ width: '375px', top: '134px', right: '64px', zIndex: 400 }} />

                    <img src="/images/Artboard 61.png" id="start-img" alt="text" style={{ width: '45px', top: '86px', left: '33px', zIndex: 400, transform: 'rotateZ(-93deg)', transition: 'all 0.5s' }} />

                    <Windmill className="road-windmill" containerStyles={{ top: '585px', left: '120px', zIndex: 600 }} poleStyles={{ width: '11.5px', zIndex: 600 }} fanStyles={{ top: '-40px', left: '-35px', width: '82px', zIndex: 700 }} />
                    <Windmill className="road-windmill" containerStyles={{ top: '485px', left: '525px', zIndex: 600 }} poleStyles={{ width: '11.5px', zIndex: 600 }} fanStyles={{ top: '-40px', left: '-35px', width: '82px', zIndex: 700 }} />

                    <Windmill containerStyles={{ top: '140px', left: '390px', zIndex: 600 }} poleStyles={{ width: '5px', zIndex: 600 }} fanStyles={{ top: '-18px', left: '-17px', width: '40px', zIndex: 700 }} />
                    <Windmill containerStyles={{ top: '140px', left: '355px', zIndex: 600 }} poleStyles={{ width: '5px', zIndex: 600 }} fanStyles={{ top: '-18px', left: '-15px', width: '35px', zIndex: 700 }} />
                    <Windmill containerStyles={{ top: '140px', left: '435px', zIndex: 600 }} poleStyles={{ width: '5px', zIndex: 600 }} fanStyles={{ top: '-18px', left: '-15px', width: '35px', zIndex: 700 }} />

                    <Windmill containerStyles={{ top: '600px', left: '860px', zIndex: 600 }} poleStyles={{ width: '5px', zIndex: 600 }} fanStyles={{ top: '-18px', left: '-17px', width: '40px', zIndex: 700 }} />
                    <Windmill containerStyles={{ top: '575px', left: '885px', zIndex: 600 }} poleStyles={{ width: '5px', zIndex: 600 }} fanStyles={{ top: '-18px', left: '-17px', width: '40px', zIndex: 700 }} />
                    <Windmill containerStyles={{ top: '600px', left: '900px', zIndex: 600 }} poleStyles={{ width: '5px', zIndex: 600 }} fanStyles={{ top: '-18px', left: '-17px', width: '40px', zIndex: 700 }} />

                    <Windmill containerStyles={{ top: '340px', left: '1100px', zIndex: 600 }} poleStyles={{ width: '5px', zIndex: 600 }} fanStyles={{ top: '-18px', left: '-17px', width: '40px', zIndex: 700 }} />
                    <Windmill containerStyles={{ top: '320px', left: '1145px', zIndex: 600 }} poleStyles={{ width: '5px', zIndex: 600 }} fanStyles={{ top: '-18px', left: '-17px', width: '40px', zIndex: 700 }} />

                    <BouncingArrow />

                    <img src="/images/Artboard 62.png" id="solar-slide" alt="solar_slide" style={{ width: '250px', top: '127px', right: '576px', zIndex: 100, transformOrigin: 'center' }} />

                    <div id="mid-renewable-tile" className="absolute" style={{ top: '450px', left: '70px', width: '4.7rem', height: '4.4rem' }}>
                        <img src="/images/Artboard 59.png" className="inline relative" style={{ top: '0px', width: '74px', left: '0px' }} alt="tile" />
                        <img src="/images/Artboard 55.png" className="inline relative" style={{ top: '-47px', width: '34px', left: '22px' }} alt="text" />
                        <img src="/images/Artboard 60.png" id="circular-text" className="inline relative" style={{ top: '-91px', width: '65px', left: '6px', borderRadius: '50%' }} alt="circular_text" />
                    </div>

                    <div id="last-renewable-tile" className="absolute" style={{ top: '396px', left: '1071px', width: '91px', height: '74px' }}>
                        <img src="/images/Artboard 56.png" className="inline relative" style={{ top: '0px', width: '91px', left: '0px' }} alt="tile" />
                        <img src="/images/Artboard 57.png" className="inline relative" style={{ top: '-53px', width: '42px', left: '25px' }} alt="text" />
                        <img src="/images/Artboard 60.png" id="last-circular-text" className="inline relative" style={{ top: '-96px', width: '67px', left: '12px', borderRadius: '50%' }} alt="circular_text" />
                    </div>

                    <img src="/images/Artboard 101.png" id="text-bubble" className="relative inline" style={{ zIndex: 600, width: '250px', top: '246px', right: '298px', transform: 'scale(0)' }} alt="text-bubble" />

                    <img src="/images/Smoke01.png" className="smoke" id="Smoke01" alt="Smoke01" style={{ width: '169px', top: '358px', left: '720px', zIndex: 600 }} />
                    <img src="/images/Smoke02.png" className="smoke" id="Smoke02" alt="Smoke02" style={{ width: '200px', top: '330px', left: '730px', zIndex: 600 }} />
                    <img src="/images/Smoke03.png" className="smoke" id="Smoke03" alt="Smoke03" style={{ width: '150px', top: '345px', left: '730px', zIndex: 600 }} />
                </div>
                {children}
            </div>
        </div>
    );
}
