import { FIRST_AC_TILE, FIRST_DC_TILE, FIRST_SOLAR_PANEL, FIRST_WIND_TURBINE, SECOND_AC_TILE, SECOND_DC_TILE, SECOND_SOLAR_PANEL, SECOND_WIND_TURBINE } from 'constants/GameData';
import { ActionType, Card, CardObjectType, CardType, CategoryType } from 'models/Card';

export class GameHelper {
    static userTurn = () => {
        const humanToken = document.getElementById('human-token')!;
        const computerToken = document.getElementById('computer-token')!;
        humanToken.classList.remove('minimizeToken');
        computerToken.classList.add('minimizeToken');
        return 'Your Turn';
    };
    static computersTurn = () => {
        const humanToken = document.getElementById('human-token')!;
        const computerToken = document.getElementById('computer-token')!;
        computerToken.classList.remove('minimizeToken');
        humanToken.classList.add('minimizeToken');
        return "Computer's Turn";
    };
    static shuffleMessage: string = 'Shuffling deck. Please wait';

    static playerPlayedCardLog(player: number, type: CardType) {
        return `${player === 0 ? 'You' : 'Computer'} picked a ${type === CardType.Bolt ? 'Bolt' : 'Say Watt'} card`;
    }

    static playerMoveLog(player: number, card: CardObjectType, answeredCorrectly?: boolean, playerCurrentPosition?: number) {
        let text = `Move `;
        if (card?.Type === CardType.Bolt) {
            if (Number(card.MoveValue)) text += `${card.MoveValue! > 0 ? 'forward' : 'backward'} ${Math.abs(card.MoveValue!)} ${Math.abs(card.MoveValue!) !== 1 ? 'spaces' : 'space'}`;
            else if (card.Category === CategoryType.Solar) {
                if (playerCurrentPosition && playerCurrentPosition >= SECOND_SOLAR_PANEL) text += 'forward 1 space';
                else text += `to the next Solar Panel`;
            } else if (card.Category === CategoryType.Wind) {
                if (playerCurrentPosition && playerCurrentPosition >= SECOND_WIND_TURBINE) text += 'forward 1 space';
                else text += `to the next Wind Turbine`;
            }
        } else {
            if (answeredCorrectly) {
                text += `forward ${Math.abs(card.MoveValue!)} ${Math.abs(card.MoveValue!) !== 1 ? 'spaces' : 'space'}`;
            } else return '';
        }
        return text.includes('backward') ? text : text + '!';
    }

    static playerAnsweredQuizCardLog(player: number, answeredCorrectly: boolean) {
        // return `${player === 0 ? 'You' : 'Computer'} answered the question ${answeredCorrectly ? 'correctly' : 'incorrectly'}!`;
        return `${answeredCorrectly ? 'Correct' : 'Incorrect'}!`;
    }

    static playerShortCircuitLog(player: number) {
        // return `${player === 0 ? 'You have' : 'Computer has'} short circuited ${player === 0 ? "computer's" : 'your'} token!`;
        return `${player === 0 ? 'Computer has been' : 'You have been'} short circuited!`;
    }

    static playerSolarSlideMoveLog(player: number) {
        // return `${player === 0 ? 'You' : 'Computer'} will now solar sliding to the next solar panel!`;
        return `Sliding to the next solar panel!`;
    }

    static playerDCtoACMoveLog(player: number) {
        // return `${player === 0 ? 'You' : 'Computer'} will now teleport to the next AC tile!`;
        return `Teleporting to the next AC space!`;
    }

    static playerWindTurbineMoveLog(player: number) {
        // return `${player === 0 ? 'You' : 'Computer'} will now fly to the next Wind turbine!`;
        return `Flying to the next Wind turbine!`;
    }

    static arrivedAtPeakLoadPanic(player: number) {
        // return `${player === 0 ? 'You have' : 'Computer has'} arrived at Peak Load Panic! Go back 2 tiles`;
        return `Peak Load Panic! Going back 2 spaces`;
    }

    static setGameData(gameData: any) {
        localStorage.setItem('game_data', JSON.stringify(gameData));
    }

    static setWinnerText(won: boolean) {
        // return `${won ? 'You have won!' : 'Better luck next time!'}`;
        return `${won ? 'Congratulations! You reached 100% Renewable Energy!' : 'Better luck next time!'}`;
    }

    static getGameData() {
        const token = localStorage.getItem('game_data');
        return token ? JSON.parse(token) : null;
    }

    static clearGameData() {
        localStorage.removeItem('game_data');
    }

    static setPlayerInfo(playerInfo: any) {
        localStorage.setItem('player_info', JSON.stringify(playerInfo));
    }

    static arrivedOrPassedRenewableTile() {
        return `50% Renewable Energy!`;
    }

    static getPlayerInfo() {
        const token = localStorage.getItem('player_info');
        return token ? JSON.parse(token) : null;
    }

    static updatePlayerInfo(data: any) {
        const t = this.getPlayerInfo();
        const newToken = Object.assign({}, t, data);
        this.setPlayerInfo(newToken);
    }

    static clearPlayerInfo() {
        localStorage.removeItem('player_info');
    }

    static randomGenerator(min: number, max: number) {
        return Math.floor(Math.random() * (max - min)) + min;
    }

    static Shuffle(times: number, Cards: CardObjectType[]) {
        while (times--) {
            for (let i = Cards?.length - 1; i > 0; i--) {
                let j = Math.floor(Math.random() * 10000000000) % (i + 1);
                let temp = Cards[i];
                Cards[i] = Cards[j];
                Cards[j] = temp;
            }
        }

        while(Cards[0].Action === ActionType.UnitMovement && Cards[0].MoveValue! < 0) {
            const randomIndex = Math.floor(Math.random() * Cards.length) + 1;
            const randomIndexCard = Cards[randomIndex];
            Cards[randomIndex] = Cards[0];
            Cards[0] = randomIndexCard;
        }

        if (Cards.length > 26) {
            var indexOf22 = Cards.indexOf(Cards.filter((x) => x.CardId === '22')[0]);
            var indexOf28 = Cards.indexOf(Cards.filter((x) => x.CardId === '28')[0]);

            if (indexOf22 + Cards.length / 2 === indexOf28 || indexOf22 === indexOf28 + Cards.length / 2) {
                if (indexOf22 <= Cards.length / 2 - 1) {
                    [Cards[indexOf22], Cards[indexOf22 + (Cards.length / 2 - 1)]] = [Cards[indexOf22 + (Cards.length / 2 - 1)], Cards[indexOf22 + (Cards.length / 2 - 1)]];
                }
                if (indexOf28 <= Cards.length / 2 - 1) {
                    [Cards[indexOf28], Cards[indexOf28 + (Cards.length / 2 - 1)]] = [Cards[indexOf28 + (Cards.length / 2 - 1)], Cards[indexOf28 + (Cards.length / 2 - 1)]];
                }
            } else {
                if (indexOf22 <= Cards.length / 2 - 1) {
                    [Cards[indexOf22], Cards[indexOf22 + Cards.length / 2]] = [Cards[indexOf22 + Cards.length / 2], Cards[indexOf22 + Cards.length / 2]];
                }
                if (indexOf28 <= Cards.length / 2 - 1) {
                    [Cards[indexOf28], Cards[indexOf28 + Cards.length / 2]] = [Cards[indexOf28 + Cards.length / 2], Cards[indexOf28 + Cards.length / 2]];
                }
            }
        }
        return Cards;
    }

    static quizCorrectAnswerMessage(player: string, points: number) {
        if (player === '0') {
            return `You played an energy tip card and answered correctly. You received ${points} kW of clean energy!`;
        } else {
            return `Computer played an energy tip card and answered correctly. Computer received ${points} kW of clean energy!`;
        }
    }

    static quizWrongAnswerMessage(player: string, points: number) {
        if (player === '0') {
            return `You played an energy tip card and answered incorrectly. You received ${points} kW of dirty energy!`;
        } else {
            return `Computer played an energy tip card and answered incorrectly. Computer received ${points} kW of dirty energy!`;
        }
    }

    static displayStartTextVertically() {
        const startImg = document.getElementById('start-img');
        startImg!.style.transform = 'rotateZ(-93deg)';
        startImg!.style.left = '33px';
    }

    static displayStartTextHorizontally() {
        const startImg = document.getElementById('start-img');
        startImg!.style.transform = 'rotateZ(0deg)';
        startImg!.style.left = '68px';
    }

    static findNextSolarPanels(playerPosition: number): { middle: number; final: number } {
        if (playerPosition < FIRST_SOLAR_PANEL) {
            return { middle: FIRST_SOLAR_PANEL, final: SECOND_SOLAR_PANEL };
        } else if (playerPosition < SECOND_SOLAR_PANEL) {
            return { middle: SECOND_SOLAR_PANEL, final: SECOND_SOLAR_PANEL };
        } else return { middle: playerPosition + 1, final: playerPosition + 1 };
    }

    static findNextWindTurbines(playerPosition: number): { middle: number; final: number } {
        if (playerPosition < FIRST_WIND_TURBINE) {
            return { middle: FIRST_WIND_TURBINE, final: SECOND_WIND_TURBINE };
        } else if (playerPosition < SECOND_WIND_TURBINE) {
            return { middle: SECOND_WIND_TURBINE, final: SECOND_WIND_TURBINE };
        } else return { middle: playerPosition + 1, final: playerPosition + 1 };
    }

    static findNextAcAndDcTiles(playerPosition: number): { middle: number; final: number } {
        // return playerPosition < FIRST_AC_TILE ? FIRST_AC_TILE : SECOND_AC_TILE;
        return playerPosition < FIRST_DC_TILE ? { middle: FIRST_DC_TILE, final: FIRST_AC_TILE } : { middle: SECOND_DC_TILE, final: SECOND_AC_TILE };
    }

    static getQueryParamsObject(extraParam?: any) {
        const urlParams = new URLSearchParams(window.location.search);
        const entries = urlParams.entries(); //returns an iterator of decoded [key,value] tuples
        const params: any = GameHelper.paramsToObject(entries);
        if (extraParam) {
            return { ...params, ...extraParam };
        } else {
            return params;
        }
    }

    static paramsToObject = (entries: any) => {
        const result: any = {};
        for (const [key, value] of entries) {
            result[key] = value;
        }
        return result;
    };

    static checkObjectEquals = (x: any, y: any) => {
        if (x === y) return true;
        // if both x and y are null or undefined and exactly the same

        if (!(x instanceof Object) || !(y instanceof Object)) return false;
        // if they are not strictly equal, they both need to be Objects

        if (x.constructor !== y.constructor) return false;
        // they must have the exact same prototype chain, the closest we can do is
        // test there constructor.

        for (var p in x) {
            if (!x.hasOwnProperty(p)) continue;
            // other properties were tested using x.constructor === y.constructor

            if (!y.hasOwnProperty(p)) return false;
            // allows to compare x[ p ] and y[ p ] when set to undefined

            if (x[p] === y[p]) continue;
            // if they have the same strict value or identity then they are equal

            if (typeof x[p] !== 'object') return false;
            // Numbers, Strings, Functions, Booleans must be strictly equal

            if (!GameHelper.checkObjectEquals(x[p], y[p])) return false;
            // Objects and Arrays must be tested recursively
        }

        for (p in y) if (y.hasOwnProperty(p) && !x.hasOwnProperty(p)) return false;
        // allows x[ p ] to be set to undefined

        return true;
    };

    static playedCardAnimationOn = (playedCard: CardObjectType) => {
        if (playedCard.Category === CategoryType.Wind) {
            const roadWindmills = document.getElementsByClassName('road-windmill');
            for (let index = 0; index < roadWindmills.length; index++) {
                // const element: HTMLElement = roadWindmills[index] as HTMLLIElement;
                const element = roadWindmills[index] as HTMLDivElement;
                element.classList.add('heartBeat');
            }
        }

        if ((playedCard.Category === CategoryType.Solar || playedCard.Category === CategoryType.SolarAndWind) && playedCard.Action === ActionType.UnitMovement && playedCard.MoveValue && playedCard.MoveValue > 0) {
            document.getElementById('solar-panel-1')?.classList.add('heartBeat');
            document.getElementById('solar-panel-2')?.classList.add('heartBeat');
            document.getElementById('sun')?.classList.add('heartBeat');
            document.getElementById('renewable-img')?.classList.add('heartBeat');
        }

        if (playedCard.Category === CategoryType.Solar && playedCard.Action === ActionType.SquareMovement) {
            const fans = document.getElementsByClassName('sparkle');
            console.log(fans);
            for (let index = 0; index < fans.length; index++) {
                const element: HTMLElement = fans[index] as HTMLLIElement;
                element.classList.add('sparkle-animate');
            }
        }

        if (
            (playedCard.Category === CategoryType.SolarAndWind && playedCard.MoveValue && playedCard.MoveValue > 0) ||
            (playedCard.Category === CategoryType.Wind && ((playedCard.Action === ActionType.UnitMovement && playedCard.MoveValue && playedCard.MoveValue > 0) || playedCard.Action === ActionType.SquareMovement))
        ) {
            const fans = document.getElementsByClassName('fan-img');
            console.log(fans);
            for (let index = 0; index < fans.length; index++) {
                const element: HTMLElement = fans[index] as HTMLLIElement;
                console.log(fans);
                element.style.animationDuration = '1s';
            }
        }
    };

    static playedCardAnimationOff = (playedCard: CardObjectType) => {
        if (playedCard.Category === CategoryType.Wind) {
            const roadWindmills = document.getElementsByClassName('road-windmill');
            for (let index = 0; index < roadWindmills.length; index++) {
                const element = roadWindmills[index] as HTMLElement;
                element.classList.remove('heartBeat');
            }
        }

        if ((playedCard.Category === CategoryType.Solar || playedCard.Category === CategoryType.SolarAndWind) && playedCard.Action === ActionType.UnitMovement && playedCard.MoveValue && playedCard.MoveValue > 0) {
            document.getElementById('solar-panel-1')?.classList.remove('heartBeat');
            document.getElementById('solar-panel-2')?.classList.remove('heartBeat');
            document.getElementById('sun')?.classList.remove('heartBeat');
            document.getElementById('renewable-img')?.classList.remove('heartBeat');
        }

        if (playedCard.Category === CategoryType.Solar && playedCard.Action === ActionType.SquareMovement) {
            const fans = document.getElementsByClassName('sparkle');
            console.log(fans);
            for (let index = 0; index < fans.length; index++) {
                const element: HTMLElement = fans[index] as HTMLLIElement;
                element.classList.remove('sparkle-animate');
            }
        }

        if (
            (playedCard.Category === CategoryType.SolarAndWind && playedCard.MoveValue && playedCard.MoveValue > 0) ||
            (playedCard.Category === CategoryType.Wind && ((playedCard.Action === ActionType.UnitMovement && playedCard.MoveValue && playedCard.MoveValue > 0) || playedCard.Action === ActionType.SquareMovement))
        ) {
            const fans = document.getElementsByClassName('fan-img');
            for (let index = 0; index < fans.length; index++) {
                const element: HTMLElement = fans[index] as HTMLLIElement;
                element.style.animationDuration = `${GameHelper.randomGenerator(4, 7)}s`;
            }
        }
    };
}
