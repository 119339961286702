import 'components/token/Token.css';
interface IToken extends React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {}

const Token = ({ ...rest }: IToken) => {
    return (
        <>
            <img {...rest} className={`${rest.className}`} />
            {/* <div className="lightning-container">
                    <div className="lightning white"></div>
                    <div className="lightning red"></div>
                </div> */}
            <div id="first-boom-1" className="">
                <div className="shape circle big white"></div>
                <div className="shape circle white"></div>
                <div className="shape triangle big yellow"></div>
                <div className="shape disc white"></div>
                <div className="shape triangle blue"></div>
            </div>
            <div id="first-boom-2" className="second">
                <div className="shape circle big white"></div>
                <div className="shape circle white"></div>
                <div className="shape disc white"></div>
                <div className="shape triangle blue"></div>
            </div>

            <div id="second-boom-1" className="">
                <div className="shape circle big white"></div>
                <div className="shape circle white"></div>
                <div className="shape triangle big yellow"></div>
                <div className="shape disc white"></div>
                <div className="shape triangle blue"></div>
            </div>
            <div id="second-boom-2" className="second">
                <div className="shape circle big white"></div>
                <div className="shape circle white"></div>
                <div className="shape disc white"></div>
                <div className="shape triangle blue"></div>
            </div>
            {/* <style>
                {`@keyframes move {
                        from {
                            transform: translate(${squareData[fromTokenIdx].x}px, ${squareData[fromTokenIdx].y}px);
                        }
						to {
							transform: translate(${squareData[toTokenIdx ?? fromTokenIdx].x}px, ${to?.y}px);
						}
					}
					.move-square {
						animation: move ${TOKEN_SQUARE_MOVEMENT_DURATION / 1000}s forwards;
					}`}
            </style> */}
        </>
    );
};

export default Token;
