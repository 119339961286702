export type Coord = {
    x: number;
    y: number;
}
export const squareData: Coord[] = [
    { x: 77-5, y: 70-7 }, //0
    { x: 150-5, y: 70-7 },
    { x: 225-5, y: 70-7 },
    { x: 298-5, y: 70-7 },
    { x: 370-5, y: 70-7 },
    { x: 447-5, y: 70-7 }, //5
    { x: 520-5, y: 70-7 },
    { x: 590-5, y: 75-7 },
    { x: 630-5, y: 135-7 },
    { x: 635-5, y: 205-7 },
    { x: 612-5, y: 275-7 }, //10
    { x: 545-5, y: 297-7 },
    { x: 470-5, y: 297-7 },
    { x: 398-5, y: 297-7 },
    { x: 325-5, y: 297-7 },
    { x: 250-5, y: 297-7 }, //15
    { x: 177-5, y: 297-7 },
    { x: 105-5, y: 320-7 },
    { x: 84-5, y: 390-7 },
    { x: 84-5, y: 462-7 },
    { x: 84-5, y: 535-7 }, //20
    { x: 100-5, y: 605-7 },
    { x: 168-5, y: 632-7 }, 
    { x: 238-5, y: 612-7 },
    { x: 307-5, y: 585-7 },
    { x: 376-5, y: 557-7 }, //25
    { x: 445-5, y: 531-7 }, 
    { x: 512-5, y: 500-7 },
    { x: 580-5, y: 470-7 },
    { x: 647-5, y: 440-7 },
    { x: 715-5, y: 410-7 }, //30
    { x: 788-5, y: 410-7 },
    { x: 861-5, y: 410-7 },
    { x: 935-5, y: 410-7 },
    { x: 1009-5, y: 410-7 },
    { x: 1093-5, y: 410-7 }, //35
];
